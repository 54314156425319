

import React, { useState, useRef, useEffect } from 'react';
import Picker from 'emoji-picker-react';
import './frameNew.css';
// import './noAudioEnglish.css'

import chatBoxImage from '../../../static/img/chatBoxImageCrop1.png'
import arrowDown from '../../../static/img/arrowDownConvert.png'

import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
import { useFetcher, useLocation, useParams } from 'react-router-dom';




// Your JSON data
const chatData =  {
  "questions": [
    {
      "id": 1,
      "question": ["Hello and welcome to Varun Digital! How can I help you today?"],
      "customPayload": {
        "1": {
          "response": "Talk to Sales",
          "nextQuestionId": 2
        },
        "2": {
          "response": "Customer Support",
          "nextQuestionId": 20
        },
        "3": {
          "response": "Add-on Service or Package Upgrade",
          "nextQuestionId": 30
        }
      }
    },
    {
      "id": 2,
      "question": ["Before we start, Can you please tell me your name?", "Can you please tell me your email?", "Can you please tell me your phone number?"],
      "inputFields": ["name", "email", "phone"],
      "apicall": true,
      "url": "",
      "method": "POST",
      "nextQuestionId": 3
    },
    {
      "id": 3,
      "question": ["Great! We offer a range of digital marketing services including Website Development, SEO, PPC, Social media management, ORM and more. Can you tell me more about what you're looking for?"],
      "nextQuestionId": 4
    },
    {
      "id": 4,
      "question": ["Got it! Can you provide some details about your business goals and current challenges? This will help us suggest the best solutions for you."],
      "fallbackOption": {
        "trigger": ["I don't know", "I do not know"],
        "response": "That's okay! It can be tough to pinpoint exactly what you need. Here are a few questions that might help clarify your goals:\n- Are you looking to increase your online presence or drive more traffic to your site?\n- Do you want to improve your social media engagement or generate more leads?\n- Are there any specific challenges you're facing with your current marketing efforts?",
        "nextQuestionId": 5
      },
      "nextQuestionId": 5
    },
    {
      "id": 5,
      "question": ["Perfect! Would you like to schedule a free consultation with one of our experts? We can discuss your needs in more detail and provide a tailored proposal."],
      "customPayload": {
        "1": {
          "response": "yes",
          "nextQuestionId": 6
        },
        "2": {
          "response": "No problem at all! If you have any questions or need information about our services, feel free to ask. Alternatively, you can explore our website.",
          "nextQuestionId": 9
        }
      }
    },
    {
      "id": 6,
      "question": ["Please provide your preferred date and time."],
      "inputFields": ["datetime"],
      "apicall": true,
      "url": "",
      "method": "POST",
      "nextQuestionId": 8
    },
    {
      "id": 8,
      "question": ["Your live demo has been scheduled with our product specialist on {date} at {time}. We have sent you an email with the details. Looking forward to e-meeting with you!"],
      "nextQuestionId": 9
  },
  {
      "id": 9,
      "question": ["Rate Your Experience with us. Say Excellent, Good, or Average."],
      "customPayload": {
      "1": {
          "response": "Excellent",
          "nextQuestionId": 32
      },
      "2": {
          "response": "Good",
          "nextQuestionId": 33
      },
      "3": {
          "response": "Average",
          "nextQuestionId": 34
      }
      }
  },
  {
      "id": 32,
      "question": ["That's great to hear from you! Thank you for choosing Varun Digital. Have a great day!"],
      "nextQuestionId": 1
  },
  {
      "id": 33,
      "question": ["That's great to hear from you! Thank you for choosing Varun Digital. Have a great day!"],
      "nextQuestionId": 1
  },
  {
      "id": 34,
      "question": ["Thank you for your feedback! We’re always striving to improve and provide the best service possible. We appreciate you choosing Varun Digital. Have a great day!"],
      "nextQuestionId": 1
  },
  {
      "id": 20,
      "question": ["Are you experiencing any issues or need assistance with our services?"],
      "customPayload": {
          "1": {
          "response": "Yes",
          "nextQuestionId": 21
          },
          "2": {
          "response": "No",
          "nextQuestionId": 9
          }
      }
  },
  {
      "id": 21,
      "question": ["Could you please provide your email address associated with your account so I can assist you better?"],
      "inputFields": ["email"],
      "apicall" : true,
      "nextQuestionId": 22,
      "fallbackOption": {
      "api_trigger": false,
      "response": "No account is assosiated with your email ID.",
      "nextQuestionId": 5
      }
  },
  {
      "id": 22,
      "question": ["Thank you. I have raised a ticket, your ticket Id is - 000000 and escalated this to our support team who will reach out to you shortly. Is there anything else you need help with right now?"],
      "nextQuestionId": 9
  },
  {
      "id": 30,
      "question": ["We offer various add-on services like video email marketing or additional content creation. Would you like to learn more about any of these services?"],
      "customPayload": {
      "1": {
          "response": "Yes",
          "nextQuestionId": 6
      },
      "2": {
          "response": "No",
          "nextQuestionId": 31
      }
      }
  },
  {
      "id": 31,
      "question": ["No problem at all! If you have any questions or need information about our services, feel free to ask. Alternatively, you can explore our website."],
      "nextQuestionId": 9
  }
  ]
}




const NoAudioEnglish = () => {





  const languages = [
    {
      "language": "Afrikaans",
      "code": "af"
    },
    {
      "language": "Albanian",
      "code": "sq"
    },
    {
      "language": "Amharic",
      "code": "am"
    },
    {
      "language": "Arabic",
      "code": "ar"
    },
    {
      "language": "Armenian",
      "code": "hy"
    },
    {
      "language": "Azerbaijani",
      "code": "az"
    },
    {
      "language": "Basque",
      "code": "eu"
    },
    {
      "language": "Bengali",
      "code": "bn"
    },
    {
      "language": "Bosnian",
      "code": "bs"
    },
    {
      "language": "Bulgarian",
      "code": "bg"
    },
    {
      "language": "Catalan",
      "code": "ca"
    },
    {
      "language": "Chinese (Simplified)",
      "code": "zh-CN"
    },
    {
      "language": "Croatian",
      "code": "hr"
    },
    {
      "language": "Czech",
      "code": "cs"
    },
    {
      "language": "Danish",
      "code": "da"
    },
    {
      "language": "Dutch",
      "code": "nl"
    },
    {
      "language": "English",
      "code": "en"
    },
    {
      "language": "Estonian",
      "code": "et"
    },
    {
      "language": "Finnish",
      "code": "fi"
    },
    {
      "language": "French",
      "code": "fr"
    },
    {
      "language": "Galician",
      "code": "gl"
    },
    {
      "language": "Georgian",
      "code": "ka"
    },
    {
      "language": "German",
      "code": "de"
    },
    {
      "language": "Greek",
      "code": "el"
    },
    {
      "language": "Hebrew",
      "code": "iw"
    },
    {
      "language": "Hindi",
      "code": "hi"
    },
    {
      "language": "Hungarian",
      "code": "hu"
    },
    {
      "language": "Icelandic",
      "code": "is"
    },
    {
      "language": "Indonesian",
      "code": "id"
    },
    {
      "language": "Irish",
      "code": "ga"
    },
    {
      "language": "Italian",
      "code": "it"
    },
    {
      "language": "Japanese",
      "code": "ja"
    },
    {
      "language": "Javanese",
      "code": "jw"
    },
    {
      "language": "Kannada",
      "code": "kn"
    },
    {
      "language": "Kazakh",
      "code": "kk"
    },
    {
      "language": "Khmer",
      "code": "km"
    },
    {
      "language": "Korean",
      "code": "ko"
    },
    {
      "language": "Lao",
      "code": "lo"
    },
    {
      "language": "Latvian",
      "code": "lv"
    },
    {
      "language": "Lithuanian",
      "code": "lt"
    },
    {
      "language": "Macedonian",
      "code": "mk"
    },
    {
      "language": "Malay",
      "code": "ms"
    },
    {
      "language": "Malayalam",
      "code": "ml"
    },
    {
      "language": "Maltese",
      "code": "mt"
    },
    {
      "language": "Mongolian",
      "code": "mn"
    },
    {
      "language": "Nepali",
      "code": "ne"
    },
    {
      "language": "Norwegian",
      "code": "no"
    },
    {
      "language": "Pashto",
      "code": "ps"
    },
    {
      "language": "Persian",
      "code": "fa"
    },
    {
      "language": "Polish",
      "code": "pl"
    },
    {
      "language": "Portuguese",
      "code": "pt"
    },
    {
      "language": "Romanian",
      "code": "ro"
    },
    {
      "language": "Russian",
      "code": "ru"
    },
    {
      "language": "Serbian",
      "code": "sr"
    },
    {
      "language": "Sinhala",
      "code": "si"
    },
    {
      "language": "Slovak",
      "code": "sk"
    },
    {
      "language": "Slovenian",
      "code": "sl"
    },
    {
      "language": "Somali",
      "code": "so"
    },
    {
      "language": "Spanish",
      "code": "es"
    },
    {
      "language": "Sundanese",
      "code": "su"
    },
    {
      "language": "Swahili",
      "code": "sw"
    },
    {
      "language": "Swedish",
      "code": "sv"
    },
    {
      "language": "Tamil",
      "code": "ta"
    },
    {
      "language": "Telugu",
      "code": "te"
    },
    {
      "language": "Thai",
      "code": "th"
    },
    {
      "language": "Turkish",
      "code": "tr"
    },
    {
      "language": "Ukrainian",
      "code": "uk"
    },
    {
      "language": "Urdu",
      "code": "ur"
    },
    {
      "language": "Uzbek",
      "code": "uz"
    },
    {
      "language": "Vietnamese",
      "code": "vi"
    },
    {
      "language": "Welsh",
      "code": "cy"
    },
    {
      "language": "Zulu",
      "code": "zu"
    }
  ]




  const [inputValue, setInputValue] = useState('');
  const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
  const [staticApiVideo, setStaticApiVideo] = useState('');
  const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');




  const [audioToText, setAudioToText] = useState('');

  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);

  const [recordMessage, setRecordMessage] = useState(false);

  const [isApiCalled, setIsApiCalled] = useState(false);

  const videoRef = useRef(null);
  const videoApiRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const chatHeadRef = useRef(null);
  const messagesEndRef = useRef(null); // Ref for the autoscroll

  const inputRef = useRef(null);

  const dropdownAvatarRef = useRef(null);
  const dropdownLanguageRef = useRef(null);

  const searchInputRef = useRef(null);



  const [showInitialText, setShowInitialText] = useState(true);
  const [recentBotIndex, setRecentBotIndex] = useState(null);

  const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
  const [checkAudio, setCheckAudio] = useState(false);

  const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

  const [showLanguages, setShowLanguages] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLanguages, setFilteredLanguages] = useState(languages);



  const [showAvatar, setShowAvatar] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [languageCode, setLanguageCode] = useState('en');






  const [isRecordingTemp, setIsRecordingTemp] = useState(false);
  const [audioUrlTemp, setAudioUrlTemp] = useState(null);
  const [apiResponseTemp, setApiResponseTemp] = useState('');
  const mediaRecorderRefTemp = useRef(null);
  const recordedChunksRefTemp = useRef([]);
  const mediaStreamRefTemp = useRef(null);


  const audioRef = useRef(null);

  const audioConstraintsTemp = {
    audio: {
      sampleRate: 16000,
      channelCount: 1,
      echoCancellation: false,
      noiseSuppression: false,
      autoGainControl: false,
    },
  };




  // taking params from parent app and passing in iframe api

  // const [params, setParams] = useState({
  //   companyName: '',
  //   moduleName: '',
  //   avatarName: ''
  // });

  // useEffect(() => {
  //   const receiveMessage = (event) => {

  //     const { companyName, moduleName, avatarName } = event.data;
  //     setParams({ companyName, moduleName, avatarName });

  //   };

  //   window.addEventListener('message', receiveMessage, false);

  //   return () => {
  //     window.removeEventListener('message', receiveMessage);
  //   };
  // }, []);





  // taking params from iframe url only and passing in iframe api


  const params = useLocation();

  const pathSegments = params.pathname.split('/').filter(segment => segment);

  const companyName = pathSegments[pathSegments.length - 3];
  const moduleName = pathSegments[pathSegments.length - 2];
  const avatarName = pathSegments[pathSegments.length - 1];








  const avatarList = [
    { name: 'Natasha', image: 'image1.png' },
    // { name: 'Avatar2', image: 'image2.png' },
  ];




  const defaultAvatar = avatarList[0];

  const [selectedAvatar, setSelectedAvatar] = useState({
    name: defaultAvatar.name,
    image: defaultAvatar.image,
  });




  // user bot states and functions starts

  const [currentQuestionId, setCurrentQuestionId] = useState(1); // Start with the first question (ID 1)
  const [messagesVirtAssist, setMessagesVirtAssist] = useState([]); // Store chat history
  const [inputValueVirtAssist, setInputValueVirtAssist] = useState(""); // For text input
  const [followUpIndex, setFollowUpIndex] = useState(0); // To track follow-up questions
  const [errorMessage, setErrorMessage] = useState(""); // For validation error messages



  const [nameVirtAssist, setNameVirtAssist] = useState("");
  const [emailVirtAssist, setEmailVirtAssist] = useState("");
  const [phoneVirtAssist, setPhoneVirtAssist] = useState("");
  const [sessionIdVirtAssist, setSessionIdVirtAssist] = useState("");
  const [dateTimeVirtAssist, setDateTimeVirtAssist] = useState("");

  const messagesEndRefUserBot = useRef(null); // Ref for the autoscroll


  useEffect(() => {
    scrollToBottom();
  }, [messagesVirtAssist]);

  const scrollToBottom = () => {
    if (messagesEndRefUserBot.current) {
      messagesEndRefUserBot.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Find the current question based on the ID
  const currentQuestion = chatData.questions.find((q) => q.id === currentQuestionId);

  useEffect(() => {
    if (currentQuestion) {
      // Add the current question to the chat history
      setMessagesVirtAssist((prev) => [
        ...prev,
        { user: false, text: currentQuestion.question[followUpIndex] }
      ]);
    }
  }, [currentQuestion, followUpIndex]);

  // Handle user's text input submission
  useEffect(() => {
    const generateSessionId = () => {
      const randomId = Math.floor(10000000 + Math.random() * 90000000).toString();
      setSessionIdVirtAssist(randomId);
    };

    generateSessionId();
  }, []); // This runs only once when the component mounts

  const handleSubmit = () => {
    setErrorMessage(""); // Clear previous error messages

    if (!inputValueVirtAssist.trim()) {
      setErrorMessage("Error: Input cannot be empty.");
      return;
    }

    const userResponse = inputValueVirtAssist.trim();

    // Check if the response matches any option in customPayload
    const matchedOption = Object.values(currentQuestion.customPayload || {}).find(option => {
      const optionResponse = Array.isArray(option.response) ? option.response[0] : option.response;
      return typeof optionResponse === "string" && optionResponse.toLowerCase() === userResponse.toLowerCase();
    });

    if (matchedOption) {
      // If a match is found, treat it as if the user selected an option
      setFollowUpIndex(0);
      setCurrentQuestionId(matchedOption.nextQuestionId);
    } else {
      // If no match, add an error message
      // setMessagesVirtAssist(prev => [
      //   ...prev,
      //   { user: false, text: "Sorry, I didn't understand that. Please choose one of the options." }
      // ]);
    }

    // Continue with the original form handling (for name, email, phone, etc.)
    if (currentQuestion.inputFields && currentQuestion.inputFields.includes("datetime")) {
      const inputDate = new Date(userResponse);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Reset current date time to midnight

      if (isNaN(inputDate.getTime())) {
        setErrorMessage("Error: Please enter a valid date and time.");
        return;
      }

      if (inputDate < currentDate) {
        setErrorMessage("Error: The date must be today or a future date.");
        return;
      }

      const dateTimeVirtAssist = userResponse;
      setDateTimeVirtAssist(dateTimeVirtAssist);
      const payload = {
        session_id: sessionIdVirtAssist,
        date_time: dateTimeVirtAssist,
      };

      fetch("https://avengine.pranathiss.com/api/schedule-meeting/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data);

          // Extract and format the start_time
          const startTime = new Date(data.start_time).toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          // Construct the message for the chat
          const meetingMessage = `Your meeting "${data.topic}" is scheduled on ${startTime}. Join using this link: ${data.join_url}`;

          // Add the message to the chat
          setMessagesVirtAssist(prev => [
            ...prev,
            { user: false, text: meetingMessage }
          ]);

          // Reset fields if necessary
          setDateTimeVirtAssist("");
        })
        .catch(error => {
          console.error("Error:", error);
          setErrorMessage("Error: Failed to schedule the meeting.");
        });

    }

    if (followUpIndex === 0) {
      setNameVirtAssist(userResponse);
    } else if (followUpIndex === 1) {
      if (!isValidEmail(userResponse)) {
        setErrorMessage("Error: Please enter a valid email address.");
        return;
      }
      setEmailVirtAssist(userResponse);
    } else if (followUpIndex === 2) {
      if (!isValidPhone(userResponse)) {
        setErrorMessage("Error: Please enter a valid phone number.");
        return;
      }
      setPhoneVirtAssist(userResponse);

      const payload = {
        name: nameVirtAssist,
        email: emailVirtAssist,
        phone: userResponse,
        session_id: sessionIdVirtAssist,
        date_time: dateTimeVirtAssist
      };

      fetch("https://avengine.pranathiss.com/api/add-lead-response/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(data => {
          console.log("Success:", data);
          setNameVirtAssist("");
          setEmailVirtAssist("");
          setPhoneVirtAssist("");
          setDateTimeVirtAssist("");
        })
        .catch(error => {
          console.error("Error:", error);
          setErrorMessage("Error: Failed to submit the form.");
        });
    }

    handleUserResponse(userResponse); // Call the handleUserResponse function with the trimmed input
  };








  // Function to validate email
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
    return emailPattern.test(email);
  };

  // Function to validate phone number (simple digit check)
  const isValidPhone = (phone) => {
    const phonePattern = /^\d{10}$/; // Assuming a 10-digit phone number
    return phonePattern.test(phone);
  };

  // Handle key press events in the input field
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  // Handle user response
  const handleUserResponse = (response) => {
    console.log('User input:', response);

    // Clear error message when an option is selected
    setErrorMessage("");

    // Convert response to string if it's not already
    const userResponse = typeof response === "string" ? response : String(response);

    // Add the user's response to the chat history as a user message
    setMessagesVirtAssist((prev) => [...prev, { user: true, text: userResponse }]);

    // Check if there are more follow-up questions to ask
    if (currentQuestion.inputFields) {
      if (followUpIndex < currentQuestion.inputFields.length - 1) {
        // Move to the next follow-up question
        setFollowUpIndex((prev) => prev + 1);
      } else {
        // All follow-ups are done, reset followUpIndex
        setFollowUpIndex(0);
        setCurrentQuestionId(currentQuestion.nextQuestionId);
      }
    } else {
      // Handle options selection from customPayload
      const matchedOption = Object.values(currentQuestion.customPayload || {}).find(option => {
        const optionResponse = Array.isArray(option.response) ? option.response[0] : option.response;

        // Convert optionResponse to string and compare it safely
        return typeof optionResponse === "string" && optionResponse.toLowerCase() === userResponse.toLowerCase();
      });

      if (matchedOption) {
        // Reset followUpIndex and go to the next question based on the selected option
        setFollowUpIndex(0);
        setCurrentQuestionId(matchedOption.nextQuestionId);
      } else if (!currentQuestion.customPayload) {
        // If there's no customPayload, proceed to the next question directly
        setCurrentQuestionId(currentQuestion.nextQuestionId);
      } else {
        // If no matching options, show an error message
        // setMessagesVirtAssist(prev => [
        //   ...prev,
        //   { user: false, text: "Sorry, I didn't understand that. Please choose one of the options." }
        // ]);
      }
    }

    // Clear the input field after processing
    setInputValueVirtAssist("");
  };









  // user bot states and functions ends


  useEffect(() => {

    if (avatarName) {
      const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());

      if (foundAvatar) {
        setSelectedAvatar({
          name: foundAvatar.name,
          image: foundAvatar.image,
        });
      } else {
        setSelectedAvatar(defaultAvatar);
      }
    } else {
      setSelectedAvatar(defaultAvatar);
    }
  }, [avatarName]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
        setShowAvatar(false);
      }
    };

    if (showAvatar) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAvatar]);





  const handleAvatarToggle = () => {
    setShowAvatar(!showAvatar);
  };

  const handleLanguageToggle = () => {
    setShowLanguages(!showLanguages);
    if (!showLanguages) {
      setFilteredLanguages(languages);
      setSearchTerm('');
    }
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language.language);
    setSearchTerm('');
    setFilteredLanguages(languages);
    setShowLanguages(false);
    setLanguageCode(language.code)
  };


  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filtered = languages.filter((language) =>
      language.language.toLowerCase().startsWith(searchTerm)
    );

    setFilteredLanguages(filtered);
  };



  useEffect(() => {
    if (showLanguages) {
      searchInputRef.current.focus();
    }
  }, [showLanguages]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownLanguageRef.current && !dropdownLanguageRef.current.contains(event.target)) {
        setShowLanguages(false);
      }
    };

    if (showLanguages) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showLanguages]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && filteredLanguages.length > 0) {
      handleLanguageSelect(filteredLanguages[0]);
    }
  };



  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
    setShowAvatar(false);
  };


  const handleWelcomeVideoEnd = () => {
    setShowWelcomeVideo(false);
  };

  useEffect(() => {

    const showTimeout = setTimeout(() => {
      setShowWelcomeMsg(true);
    }, 1);

    const hideTimeout = setTimeout(() => {
      setShowWelcomeMsg(false);
    }, 3000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, []);



  useEffect(() => {
    const callApiOnPageLoad = async () => {
      const payload = { user_input: 'hi' };

      try {
        const response = await fetch(
          `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setWelcomeApiMsg(data.responses[0])
          setStaticApiVideo(data.static_video)
          setWelcomeApiVideo(data.video_path)
        } else {
          console.error('API error:', response.statusText);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    callApiOnPageLoad();
  }, [companyName, moduleName, selectedAvatar]);





  useEffect(() => {
    const latestBotIndex = messages
      .map((item, index) => (item.type === 'bot' ? index : null))
      .filter(index => index !== null)
      .pop();

    if (latestBotIndex !== null) {
      setRecentBotIndex(latestBotIndex);

      // const timer = setTimeout(() => {
      //   setShowInitialText(false);
      // }, 1300);

      setShowInitialText(true);

      // return () => clearTimeout(timer);
    }
  }, [messages]);




  const handleSendMessage = async () => {
    setInputValue(''); // Clear the input field at the start

    if (inputValue.trim() || audioToText) {
      setLoading(true);
      setIsApiCalled(true);

      const userMessage = {
        message: audioToText || inputValue.trim() || '',
        type: 'user',
        audioUrl: audioToText,
        isActive: true,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const payload = {
        user_input: audioToText ? audioToText : inputValue.trim() || '',
        lang: languageCode,
        companyName: companyName,
        moduleName: moduleName,
        avatarName: selectedAvatar.name,
      };

      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      try {
        const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();

        setAudioToText('');
        setAudioBlob(null);
        setTimer(0);

        handleResponse(data); // Call handleResponse to play audio and video

        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };





  // const handleResponse = (data) => {
  //   // Check if there are bot responses
  //   if (data.responses && data.responses.length > 0) {
  //     const botMessage = data.responses.join(' ');
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       {
  //         message: botMessage,
  //         type: 'bot',
  //       },
  //     ]);
  //   } else {
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       {
  //         message: "Could you please repeat?",
  //         type: 'bot',
  //       },
  //     ]);
  //   }

  //   // Check if there is a video path in the response
  //   if (data.video_path) {
  //     const fullVideoUrl = data.video_path;
  //     setVideoUrl(fullVideoUrl);

  //     // If there is audio data in base64 format, handle the audio
  //     if (data.audio_data) {
  //       // Convert the base64 audio data to a Blob
  //       const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3'); // Assuming audio is in mp3 format
  //       const audioUrl = URL.createObjectURL(audioBlob); // Create a URL for the audio Blob





  //       // Create audio and video elements
  //       const videoElement = document.createElement('video');
  //       videoElement.src = fullVideoUrl;
  //       videoElement.controls = true;
  //       videoElement.autoplay = true;
  //       videoElement.muted = true; // Mute the video to avoid overlapping sound

  //       const audioElement = document.createElement('audio');
  //       audioElement.src = audioUrl;
  //       audioElement.autoplay = true;


  //       // const audioElement = new Audio(audioUrl);
  //     audioRef.current = audioElement; 

  //       // Sync the audio and video playback
  //       videoElement.addEventListener('play', () => {
  //         audioElement.play();
  //       });

  //       videoElement.addEventListener('pause', () => {
  //         audioElement.pause();
  //       });

  //       // Append the video element to a container (assuming there is a container for the video)
  //       const videoContainer = document.getElementById('video-container');
  //       videoContainer.innerHTML = ''; // Clear any previous video
  //       videoContainer.appendChild(videoElement);
  //     } else {
  //       console.log('No audio data received.');
  //     }
  //   } else {
  //     setVideoUrl(''); // Clear the video if no path is provided
  //   }
  // };

  // Utility function to convert base64 to Blob





  useEffect(() => {
    // Play the video whenever the videoUrl changes
    // if (videoUrl && videoRef.current) {
    //     videoRef.current.src = videoUrl;
    //     videoRef.current.load(); // Ensure video loads correctly
    //     videoRef.current.play(); // Play the video
    // }
  }, [videoUrl]); // Add videoUrl to the dependency array to track changes

  const handleResponse = (data) => {
    // Handle bot messages
    if (data.responses && data.responses.length > 0) {
      const botMessage = data.responses.join(' ');
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: botMessage, type: 'bot' },
      ]);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: "Could you please repeat?", type: 'bot' },
      ]);
    }

    // Handle video from API
    if (data.video_path) {
      const fullVideoUrl = data.video_path;
      setVideoUrl(fullVideoUrl); // Set the new video URL




      // Stop any previous audio before handling the new response
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset previous audio
      }
      if (languageCode == 'en') {
        // For English language, play the video sound and ensure it's muted based on current state
        if (videoRef.current) {
          videoRef.current.src = fullVideoUrl;
          //   videoRef.current.muted = !isSoundOn; // Play video with or without sound based on sound state
          videoRef.current.play();

          // Remove any previous 'ended' event listeners and add the new one
          videoRef.current.removeEventListener('ended', playStaticVideo);
          videoRef.current.addEventListener('ended', playStaticVideo);
        }
      } else {
        // For non-English languages, mute the video and play the API audio key
        if (videoRef.current) {
          videoRef.current.src = fullVideoUrl;
          videoRef.current.muted = true; // Mute video sound
          videoRef.current.play();

          // Remove any previous 'ended' event listeners and add the new one
          videoRef.current.removeEventListener('ended', playStaticVideo);
          videoRef.current.addEventListener('ended', playStaticVideo);
        }

        // Handle API-provided audio key for non-English languages
        if (data.audio_data) {

          const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3');
          const audioUrl = URL.createObjectURL(audioBlob);
          const audioElement = new Audio(audioUrl);
          audioRef.current = audioElement;
          // Ensure audio is played or muted based on the sound toggle
          audioElement.muted = !isSoundOn;
          audioElement.play();

          // Handle audio end event
          audioElement.addEventListener('ended', () => {
            if (videoRef.current) {
              videoRef.current.pause();
              playStaticVideo(); // Play static video after API video finishes
            }
          });
        }
      }
    } else {
      setVideoUrl(''); // Clear the video if no path is provided
    }
  };

  // Function to toggle sound for both video and audio
  const toggleSound = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (languageCode === 'en') {
        if (videoRef.current) {
          videoRef.current.muted = !newIsSoundOn; // Toggle video sound based on state
        }
      } else {
        if (audioRef.current) {
          audioRef.current.muted = !newIsSoundOn; // Toggle API-provided audio key sound
        }
      }

      return newIsSoundOn;
    });
  };




  // Function to play the static video
  const playStaticVideo = () => {
    if (videoRef.current) {
      videoRef.current.src = staticApiVideo; // Set the static video source
      videoRef.current.muted = true; // Ensure the static video is muted
      videoRef.current.play(); // Play the static video
    }
  };




  const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  useEffect(() => {
    if (videoUrl) {
      setVideoLoading(true);
      const video = videoRef.current;
      if (video) {
        video.onloadeddata = () => {
          setVideoLoading(false);

          video.onended = () => {
            setVideoUrl('');
          };
        };
      }

    }
  }, [videoUrl]);




  // useEffect(() => {
  //   if (!loading && !recording) {
  //     inputRef.current?.focus();
  //   }
  //   if (showWelcomeVideo) {
  //     inputRef.current?.focus();
  //   }
  // }, [loading, recording, inputValue, messages]);


  // recording check 


  // const startRecording = async () => {
  //   setRecording(true);
  //   setRecordMessage(false)
  //   setTimer(0);
  //   audioChunksRef.current = [];
  //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //   mediaRecorderRef.current = new MediaRecorder(stream);
  //   mediaRecorderRef.current.ondataavailable = (event) => {
  //     if (event.data.size > 0) {
  //       audioChunksRef.current.push(event.data);
  //     }
  //   };
  //   mediaRecorderRef.current.onstop = () => {
  //     const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
  //     setAudioBlob(blob);
  //   };
  //   mediaRecorderRef.current.start();
  //   timerRef.current = setInterval(() => setTimer((prev) => prev + 1), 1000);
  // };

  // const stopRecording = () => {
  //   setRecordMessage(true)
  //   setRecording(false);
  //   if (mediaRecorderRef.current) {
  //     mediaRecorderRef.current.stop();
  //   }
  //   clearInterval(timerRef.current);
  // };

  useEffect(() => {
    if (audioBlob) {
      sendAudioToApi(audioBlob);
    }
  }, [audioBlob]);


  useEffect(() => {
    const timer = setTimeout(() => {

      setRecordMessage(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [recordMessage]);

  // const sendAudioToApi = async (blob) => {
  //   try {
  //     const base64Audio = await convertBlobToBase64(blob);

  //     const fileExtension = blob.type.split('/')[1];

  //     const formData = new FormData();
  //     formData.append('audio_file', base64Audio.split(',')[1]);
  //     formData.append('file_extension', fileExtension);


  //     const response = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
  //       method: 'POST',
  //       body: formData
  //     });

  //     const data = await response.json();
  //     setAudioToText(data.response)
  //     if (data.response.length > 0) {
  //       setCheckAudio(false)
  //     }

  //     // if (data.response) {
  //     //   setTimeout(() => {
  //     //     setRecordMessage(false)
  //     //     }, 880);
  //     //   // }, 6200);
  //     // }


  //   } catch (error) {
  //     console.error('Error sending audio:', error);
  //   }
  //   finally {
  //     setCheckAudio(true)
  //   }
  // };


  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };


  useEffect(() => {
    // if (videoRef.current) {
    //     setVideoLoading(true);
    //     videoRef.current.load();
    //     videoRef.current.play().finally(() => {
    //         setVideoLoading(false);
    //     });
    // }
  }, [videoUrl]);



  //   const toggleSound = () => {
  //     if (audioRef.current) {
  //       audioRef.current.muted = isSoundOn;
  //       setIsSoundOn(!isSoundOn);
  //     }
  //   };




  // const toggleSound = () => {
  //   if (videoRef.current && audioRef.current) {
  //     if (isSoundOn) {
  //       // Mute both video and external audio
  //       videoRef.current.muted = true;
  //       audioRef.current.muted = true; // Mute external audio
  //     } else {
  //       // Unmute both video and external audio
  //       videoRef.current.muted = false;
  //       audioRef.current.muted = false; // Unmute external audio
  //     }
  //     // Toggle sound state
  //     setIsSoundOn(!isSoundOn);
  //   }
  // };











  const handleChatBotOpen = () => {
    setShowChatBot(!showChatBot);
  };

  useEffect(() => {
    setShowChatBot(true);
  }, []);


  useEffect(() => {
    handleSendMessage()
  }, [audioToText])

  const handleVideoEnd = () => {
    setVideoUrl(staticApiVideo)
  }


  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }, [messages, showChatBot]);





  // audio functionality for ios 

  // const sendAudioToApi = async () => {
  //     if (!isRecordingTemp) {
  //         try {
  //             // Request microphone access
  //             mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);

  //             // Detect if the user is on iOS and use 'audio/mp4' else 'audio/webm'
  //             const mimeType = isIOS() ? 'audio/mp4' : 'audio/webm';
  //             mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });

  //             mediaRecorderRefTemp.current.ondataavailable = (event) => {
  //                 if (event.data.size > 0) {
  //                     recordedChunksRefTemp.current.push(event.data);
  //                 }
  //             };

  //             mediaRecorderRefTemp.current.onstop = async () => {
  //                 const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
  //                 recordedChunksRefTemp.current = [];

  //                 // Convert blob to Base64
  //                 const readerTemp = new FileReader();
  //                 readerTemp.readAsDataURL(blobTemp);
  //                 readerTemp.onloadend = async () => {
  //                     const base64AudioTemp = readerTemp.result.split(',')[1];

  //                     // Display audio for playback
  //                     const audioUrlTemp = URL.createObjectURL(blobTemp);
  //                     setAudioUrlTemp(audioUrlTemp);

  //                     // Prepare the form data
  //                     const formDataTemp = new FormData();
  //                     formDataTemp.append('audio_file', base64AudioTemp);
  //                     formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');

  //                     // Call the API
  //                     try {
  //                         const responseTemp = await fetch(`https://speech.pranathiss.com/api/vad-base64-speech-to-text/${selectedLanguage}/`, {
  //                             method: 'POST',
  //                             body: formDataTemp,
  //                         });

  //                         const resultTemp = await responseTemp.json();
  //                         console.log('API Response:', resultTemp.response);

  //                         setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
  //                         setAudioToText(resultTemp.response);

  //                         if (resultTemp.response.length > 0) {
  //                             setCheckAudio(false);
  //                         }
  //                     } catch (error) {
  //                         console.error('Error calling the API:', error);
  //                         setApiResponseTemp(`Error: ${error.message}`);
  //                     }
  //                 };
  //             };

  //             mediaRecorderRefTemp.current.start();
  //             setIsRecordingTemp(true);
  //         } catch (error) {
  //             // Handle errors and provide feedback to the user
  //             if (error.name === 'NotAllowedError') {
  //                 console.error('Microphone permission denied: ', error);
  //                 alert('Microphone access was denied. Please enable it in the settings.');
  //             } else {
  //                 console.error('Error accessing microphone: ', error);
  //                 alert('An error occurred while trying to access your microphone. Please try again.');
  //             }
  //         }
  //     } else {
  //         // Stop the recording
  //         mediaRecorderRefTemp.current.stop();
  //         mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
  //         setIsRecordingTemp(false);
  //     }
  // };



  const sendAudioToApi = async () => {
    if (!isRecordingTemp) {
      try {
        // Request microphone access
        mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);
  
        const mimeType = isIOS() || isSafari() ? 'audio/mp4' : 'audio/webm';
  
        if (!MediaRecorder.isTypeSupported(mimeType)) {
          console.warn(`${mimeType} is not supported on this browser.`);
          return;
        }
  
        mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });
  
        mediaRecorderRefTemp.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunksRefTemp.current.push(event.data);
          }
        };
  
        mediaRecorderRefTemp.current.onstop = async () => {
          const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
          recordedChunksRefTemp.current = [];
  
          const readerTemp = new FileReader();
          readerTemp.readAsDataURL(blobTemp);
          readerTemp.onloadend = async () => {
            const base64AudioTemp = readerTemp.result.split(',')[1];
  
            const formDataTemp = new FormData();
            formDataTemp.append('audio_file', base64AudioTemp);
            formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');
  
            try {
              const responseTemp = await fetch(`https://speech.pranathiss.com/api/vad-base64-speech-to-text/${selectedLanguage}/`, {
                method: 'POST',
                body: formDataTemp,
              });
  
              const resultTemp = await responseTemp.json();
              const transcribedText = resultTemp.response.trim();
  
              console.log('Transcribed Text:', transcribedText);
  
              setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
              setAudioToText(transcribedText);
  
              // Always display the transcribed text in chat
              setMessagesVirtAssist(prev => [
                ...prev,
                { user: true, text: transcribedText }  // Always add the user's transcribed text
              ]);
  
              // Check if the transcribed text matches any option in customPayload
              const matchedOption = Object.values(currentQuestion.customPayload || {}).find(option => {
                const optionResponse = Array.isArray(option.response) ? option.response[0] : option.response;
                return typeof optionResponse === "string" && optionResponse.toLowerCase() === transcribedText.toLowerCase();
              });
  
              if (matchedOption) {
                // If a match is found, treat it like the user selected the option
                setFollowUpIndex(0);
                setCurrentQuestionId(matchedOption.nextQuestionId);
              } else {
                // Optionally, show a fallback message when no match is found
                // setMessagesVirtAssist(prev => [
                //   ...prev,
                //   { user: false, text: "Sorry, I didn't understand that. Please provide valid input." }
                // ]);
              }
  
              if (transcribedText.length > 0) {
                setCheckAudio(false);
              }
  
            } catch (error) {
              console.error('Error calling the API:', error);
              setApiResponseTemp(`Error: ${error.message}`);
            }
          };
        };
  
        mediaRecorderRefTemp.current.start();
        setIsRecordingTemp(true);
      } catch (error) {
        console.error('Error accessing microphone:', error);
        alert('An error occurred while trying to access your microphone. Please try again.');
        if (error.name === 'NotAllowedError') {
          alert('Microphone access was denied. Please enable it in your settings.');
        } else if (error.name === 'NotFoundError') {
          alert('No microphone found. Please connect a microphone.');
        } else {
          alert(`An unexpected error occurred: ${error.message}`);
        }
      }
    } else {
      // Stop the recording
      mediaRecorderRefTemp.current.stop();
      mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
      setIsRecordingTemp(false);
    }
  };
  
  


  // Utility to detect Safari
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome');
  };


  // Helper function to check if the user is on iOS
  const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);


  const handleInputSearch = (e) => {
    e.stopPropagation()
  }


  return (

    <>
      {
        !showChatBot ?
          <div className="videochartvalue ">
            <div className='container'>

              <div className='headAndOutput'>

                <div className='chatHead'>
                  <div className='headIcontext'>
                    <div className='group-2'></div>
                    <div className='iconHeadChat'>
                      <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
                      <div className='headTextClass'>
                        <div className="text-wrapper miniHead">Chat with</div>
                        <div className="text-wrapper-2">AI Assistant</div>
                      </div>
                    </div>
                  </div>
                  <div className='headMinClose'>

                    <div className='languagesDivBoth'>
                      <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
                        <div className='avatarNameImgClass'>
                          {selectedAvatar.name}

                        </div>
                        <span className={`arrow ${showAvatar ? 'open' : ''}`}>
                          <img src={arrowDown} alt="" className='downArrowClass' />
                        </span>
                        {showAvatar && (
                          <ul className='languageDropdown'>
                            {avatarList
                              .filter(avatar => avatar !== selectedAvatar)
                              .map((avatar, index) => (
                                <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>

                                  {avatar.name}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>


                      <div ref={dropdownLanguageRef} className='languagesDiv' onClick={handleLanguageToggle}>

                        {selectedLanguage}

                        <span className={`arrow ${showLanguages ? 'open' : ''}`}>
                          <img src={arrowDown} alt="" className='downArrowClass' />
                        </span>

                        {showLanguages && (
                          <ul className='languageDropdown'>

                            <input
                              ref={searchInputRef}
                              type="text"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              onKeyDown={handleKeyDown}
                              onClick={handleInputSearch}
                              placeholder="Search"
                              className='searchLanguageInput'
                            />
                          </ul>
                        )}
                        {showLanguages && (
                          <ul className='languageDropdown languageSearchDropdown'>

                            {filteredLanguages.map((language, index) => (
                              <li key={index} onClick={() => handleLanguageSelect(language)} className='languageOption'>
                                <p>

                                  {language.language}
                                </p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>




                    </div>

                    <div className=' speakerIconButton' >
                      {isSoundOn ? (
                        <img src={chatSoundIconWhite} alt="Speaker" onClick={toggleSound} />
                      ) : (
                        <img src={chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
                      )}
                      {/* <img src="/img/minimizeChatWhite.png" alt="Line" onClick={handleChatBotOpen} /> */}
                      <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
                    </div>


                  </div>
                </div>



                <div id="output-container">
                  {/* <div className="messages-container">
                                        {
                                            showWelcomeMsg ?

                                                <div className='message-item receiver'>
                                                    <div className='senderIconText'>

                                                        <div className='message'>
                                                            <div className="chat-bubble">
                                                                <div className="typing">
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                    <div className="dot"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
                                                    </div>
                                                </div>
                                                :
                                                <div className='receiverIconText'>
                                                    <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
                                                    <div className="success-status">
                                                        {welcomeApiMsg}
                                                    </div>
                                                </div>
                                        }



                                        {messages.map((item, index) => (
                                            <div key={index} className={`message-item ${item.type === 'user' ? 'sender' : 'receiver'}`}>
                                                <div className='senderIconText'>
                                                    <div className="message">
                                                        {
                                                            item.audioUrl ?
                                                                recordMessage && index === recentBotIndex ? (
                                                                    <div className="chat-bubble-audio">
                                                                        <div className="typing">
                                                                            <div className="dot"></div>
                                                                            <div className="dot"></div>
                                                                            <div className="dot"></div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                    : (
                                                                        <p>{item.audioUrl}</p>
                                                                    )
                                                                :
                                                             
                                                                <p>{item.message}</p>

                                                        }
                                                    </div>
                                                    {item.type === 'user' && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
                                                    {item.type === 'bot' && <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />}
                                                </div>
                                                <div ref={messagesEndRef} /> 
                                            </div>
                                        ))}

                                       

                                        <div ref={messagesEndRef} />
                                    </div> */}










                  {/* user bot main div starts  */}



                  <div className="chat-container messages-container">
                    <div className="chat-messages" ref={messagesEndRefUserBot}>
                      {messagesVirtAssist.map((message, index) => (
                        <div key={index}>
                          {/* Skip rendering if the message is the specific one from JSON */}
                          {!message.text.includes("Your live demo has been scheduled with our product specialist") ? (
                            <>
                              {message.user ? (
                                <div className='userImageChat'>
                                  <div className={`chat-message ${message.user ? "user" : "bot"}`}>
                                    {message.text}
                                  </div>
                                  <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />
                                </div>
                              ) : (
                                <div className='botImageChat'>
                                  <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
                                  <div className={`chat-message ${message.user ? "user" : "bot"}`}>
                                    {message.text}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null} {/* Do not render if the message matches */}
                        </div>
                      ))}
                    </div>

                    {currentQuestion && currentQuestion.customPayload && (
                      <div className="options chat-messages">
                        {Object.keys(currentQuestion.customPayload).map((key) => (
                          <button key={key} onClick={() => handleUserResponse(currentQuestion.customPayload[key].response)}>
                            {currentQuestion.customPayload[key].response}
                          </button>
                        ))}
                      </div>
                    )}

                    <div ref={messagesEndRefUserBot} />
                  </div>



                  {/* user bot main div ends */}



                  <div className="hello-container" >

                    {showWelcomeVideo && welcomeApiVideo ? (
                      <video
                        ref={videoRef}
                        width="100%"
                        autoPlay
                        muted={!isSoundOn} // Mute video if language is not English or sound is off
                        onEnded={handleWelcomeVideoEnd}
                        playsInline
                        className="sharedVideoClass"
                      >
                        <source src={welcomeApiVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div className='staticVideoClass'>
                        {!isApiCalled || !videoUrl ?
                          (
                            <video
                              ref={videoRef}
                              width="100%"
                              autoPlay
                              loop
                              muted
                              playsInline
                              className="sharedVideoClass"
                            >
                              <source src={staticApiVideo} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          )
                          :
                          (
                            <video
                              ref={videoRef}
                              width="100%"
                              autoPlay
                              muted={languageCode == "en" ? !isSoundOn : true} // Play sound only if language is English
                              onEnded={handleVideoEnd}
                              playsInline
                              className='responseVideoClass sharedVideoClass'
                            >
                              <source src={videoUrl} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          )
                        }
                      </div>
                    )}

                  </div>



                </div>

              </div>

              <div className='outputHelpfulClass'>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div className="input-container">
                  <input
                    type="text"
                    value={inputValueVirtAssist}
                    onChange={(e) => setInputValueVirtAssist(e.target.value)}
                    onKeyPress={handleKeyPress} // Handle Enter key press
                    placeholder="Type your answer..."
                  />

                  {/* {recording ? ( */}
                  {isRecordingTemp ? (
                    <div className="recording-indicator">
                      <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
                        <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
                      </div>
                    </div>
                  ) : (
                    <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
                      <img src="/img/chatMicIcon.png" alt="Mic" />
                    </div>
                  )}

                  <div className='iconButton' onClick={handleSubmit} disabled={loading || recording}>
                    <img src="/img/chatSendIcon.png" alt="Send" />
                  </div>
                </div>

              </div>
            </div>

          </div>
          :
          <div className='chatBoxImageClass' >
            <img src={chatBoxImage} alt="" onClick={handleChatBotOpen} />

          </div>
      }

    </>

  );
};

export default NoAudioEnglish;


