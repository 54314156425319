



// import React, { useState, useRef, useEffect } from 'react';
// import Picker from 'emoji-picker-react';
// import './frameNew.css';
// // import './noAudioEnglish.css'

// import chatBoxImage from '../../../static/img/chatBoxImageCrop1.png'
// import arrowDown from '../../../static/img/arrowDownConvert.png'

// import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
// import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
// import { useFetcher, useLocation, useParams } from 'react-router-dom';


// const chatData = {
//     "static_video": "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_static_new.mp4",
//     "questions": {
//         "id-1": {
//             "question": ["Hello and welcome to Varun Digital! How can I help you today?"],
//             "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_welcome.mp4"],
//             "custompayload": {
//                 "Talk to Sales": {
//                     "nextQuestionKey": "id-2"
//                 },
//                 "Customer Support": {
//                     "nextQuestionKey": "id-20"
//                 },
//                 "Add-on Service or Package Upgrade": {
//                     "nextQuestionKey": "id-30"
//                 }
//             }
//         },
//         "id-2": {
//             "question": ["Before we start, Can you please tell me your name?", "Can you please tell me your email?", "Can you please tell me your phone number?"],
//             "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_name.mp4", "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_email.mp4", "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_number.mp4"],
//             "inputFields": ["name", "email", "phone"],
//             "apicall": true,
//             "api_details": {
//                 // "url": "name email phone post api",
//                 "url": "https://avengine.pranathiss.com/api/add-lead-response/",
//                 "method": "POST",
//                 "message_key": "response",
//                 "status_key": "status",
//                 "nextQuestionKey": "id-2"

//             },
//             "nextQuestionKey": "id-3"
//         },
//         "id-3": {
//             "question": ["Great! We offer a range of digital marketing services including Website Development, SEO, PPC, Social media management, ORM and more. Can you tell me more about what you're looking for?"],
//             "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_services.mp4"],
//             "nextQuestionKey": "id-4"
//         },
//         "id-4": {
//             "question": ["Got it! Can you provide some details about your business goals and current challenges? This will help us suggest the best solutions for you."],
//             "video_path": "",
//             "fallbackOption": {
//                 "trigger": ["I don't know", "I do not know"],
//                 "response": "That's okay! It can be tough to pinpoint exactly what you need. Here are a few questions that might help clarify your goals:\n- Are you looking to increase your online presence or drive more traffic to your site?\n- Do you want to improve your social media engagement or generate more leads?\n- Are there any specific challenges you're facing with your current marketing efforts?",
//                 "nextQuestionKey": "id-5"
//             },
//             "nextQuestionKey": "id-5"
//         },
//         "id-5": {
//             "question": ["Perfect! Would you like to schedule a free consultation with one of our experts? We can discuss your needs in more detail and provide a tailored proposal."],
//             "custompayload": {
//                 "yes": {
//                     "nextQuestionKey": "id-6"
//                 },
//                 "no": {
//                     "nextQuestionKey": "id-10"
//                 }
//             }
//         },
//         "id-10": {
//             "question": ["Great! We offer a range of digital marketing services including Website Development, SEO, PPC, Social media management, ORM and more. Can you tell me more about what you're looking for?"],
//             "nextQuestionKey": "id-9"
//         },
//         "id-6": {
//             "question": ["Please provide your preferred date and time."],
//             "inputFields": ["datetime"],
//             "apicall": true,
//             "api_details": {
//                 "url": "https://avengine.pranathiss.com/api/schedule-meeting/",
//                 "method": "POST",
//                 "message_key": "response",
//                 "status_key": "status",
//                 "nextQuestionKey": "id-2"

//             },
//             "nextQuestionKey": "id-8"
//         },
//         "id-8": {
//             "question": ["Your live demo has been scheduled with our product specialist on {date} at {time}. We have sent you an email with the details. Looking forward to e-meeting with you!"],
//             "nextQuestionKey": "id-9"
//         },
//         "id-9": {
//             "question": ["Rate Your Experience with us. Say Excellent, Good, or Average."],
//             "inputFields": ["feedback_score"],
//             "custompayload": {
//                 "excellent": {
//                     "inputFields": ["feedback_score"],
//                     "apicall": true,
//                     "api_details": {
//                         "url": "https://avengine.pranathiss.com/api/save-feedback/",
//                         "method": "POST",
//                         "message_key": "response",
//                         "status_key": "status",
//                         "nextQuestionKey": "id-1"
//                     },
//                     "nextQuestionKey": "id-32"
//                 },
//                 "good": {
//                     "apicall": true,
//                     "inputFields": ["feedback_score"],
//                     "api_details": {
//                         "url": "https://avengine.pranathiss.com/api/save-feedback/",
//                         "method": "POST",
//                         "message_key": "response",
//                         "status_key": "status",
//                         "nextQuestionKey": "id-1"
//                     },
//                     "nextQuestionKey": "id-33"
//                 },
//                 "average": {
//                     "apicall": true,
//                     "inputFields": ["feedback_score"],
//                     "api_details": {
//                         "url": "https://avengine.pranathiss.com/api/save-feedback/",
//                         "method": "POST",
//                         "message_key": "response",
//                         "status_key": "status",
//                         "nextQuestionKey": "id-1"
//                     },
//                     "nextQuestionKey": "id-34"
//                 }
//             }
//         },
//         "id-32": {
//             "question": ["That's great to hear from you! Thank you for choosing Varun Digital. Have a great day!"],
//             "nextQuestionKey": "id-1"
//         },
//         "id-33": {
//             "question": ["That's great to hear from you! Thank you for choosing Varun Digital. Have a great day!"],
//             "nextQuestionKey": "id-1"
//         },
//         "id-34": {
//             "question": ["Thank you for your feedback! We’re always striving to improve and provide the best service possible. We appreciate you choosing Varun Digital. Have a great day!"],
//             "nextQuestionKey": "id-1"
//         },
//         "id-20": {
//             "question": ["Are you experiencing any issues or need assistance with our services?"],
//             "custompayload": {
//                 "yes": {
//                     "nextQuestionKey": "id-21"
//                 },
//                 "no": {
//                     "nextQuestionKey": "id-9"
//                 }
//             }
//         },
//         "id-21": {
//             "question": ["Could you please provide your email address associated with your account so I can assist you better?"],
//             "inputFields": ["email"],
//             "apicall": true,
//             "api_details": {
//                 "url": "https://avengine.pranathiss.com/api/check-existing-customer/",
//                 "method": "POST",
//                 "message_key": "response",
//                 "status_key": "status",
//                 "nextQuestionKey": "id-21"

//             },
//             "nextQuestionKey": "id-22"
//         },

//         "id-22": {
//             "question": ["Could you please elaborate about the problem you are facing?"],
//             "apicall": true,
//             "inputFields": ["desc"],
//             "api_details": {
//                 "url": "https://avengine.pranathiss.com/api/create-ticket/",
//                 "method": "POST",
//                 "message_key": "response",
//                 "status_key": "status",
//                 "nextQuestionKey": "id-22"

//             },
//             "nextQuestionKey": "id-23"
//         },
//         "id-23": {
//             "question": ["Thank you. I have raised a ticket, your ticket Id is - 000000 and escalated this to our support team who will reach out to you shortly. Is there anything else you need help with right now?"],
//             "nextQuestionKey": "id-9"
//         },
//         "id-30": {
//             "question": ["We offer various add-on services like video email marketing or additional content creation. Would you like to learn more about any of these services?"],
//             "custompayload": {
//                 "yes": {
//                     "nextQuestionKey": "id-6"
//                 },
//                 "no": {
//                     "nextQuestionKey": "id-31"
//                 }
//             }
//         },
//         "id-31": {
//             "question": ["No problem at all! If you have any questions or need information about our services, feel free to ask. Alternatively, you can explore our website."],
//             "nextQuestionKey": "id-9"
//         }
//     }
// }





// const DirectData = () => {


//     const languages = [
//         {
//             "language": "Afrikaans",
//             "code": "af"
//         },
//         {
//             "language": "Albanian",
//             "code": "sq"
//         },
//         {
//             "language": "Amharic",
//             "code": "am"
//         },
//         {
//             "language": "Arabic",
//             "code": "ar"
//         },
//         {
//             "language": "Armenian",
//             "code": "hy"
//         },
//         {
//             "language": "Azerbaijani",
//             "code": "az"
//         },
//         {
//             "language": "Basque",
//             "code": "eu"
//         },
//         {
//             "language": "Bengali",
//             "code": "bn"
//         },
//         {
//             "language": "Bosnian",
//             "code": "bs"
//         },
//         {
//             "language": "Bulgarian",
//             "code": "bg"
//         },
//         {
//             "language": "Catalan",
//             "code": "ca"
//         },
//         {
//             "language": "Chinese (Simplified)",
//             "code": "zh-CN"
//         },
//         {
//             "language": "Croatian",
//             "code": "hr"
//         },
//         {
//             "language": "Czech",
//             "code": "cs"
//         },
//         {
//             "language": "Danish",
//             "code": "da"
//         },
//         {
//             "language": "Dutch",
//             "code": "nl"
//         },
//         {
//             "language": "English",
//             "code": "en"
//         },
//         {
//             "language": "Estonian",
//             "code": "et"
//         },
//         {
//             "language": "Finnish",
//             "code": "fi"
//         },
//         {
//             "language": "French",
//             "code": "fr"
//         },
//         {
//             "language": "Galician",
//             "code": "gl"
//         },
//         {
//             "language": "Georgian",
//             "code": "ka"
//         },
//         {
//             "language": "German",
//             "code": "de"
//         },
//         {
//             "language": "Greek",
//             "code": "el"
//         },
//         {
//             "language": "Hebrew",
//             "code": "iw"
//         },
//         {
//             "language": "Hindi",
//             "code": "hi"
//         },
//         {
//             "language": "Hungarian",
//             "code": "hu"
//         },
//         {
//             "language": "Icelandic",
//             "code": "is"
//         },
//         {
//             "language": "Indonesian",
//             "code": "id"
//         },
//         {
//             "language": "Irish",
//             "code": "ga"
//         },
//         {
//             "language": "Italian",
//             "code": "it"
//         },
//         {
//             "language": "Japanese",
//             "code": "ja"
//         },
//         {
//             "language": "Javanese",
//             "code": "jw"
//         },
//         {
//             "language": "Kannada",
//             "code": "kn"
//         },
//         {
//             "language": "Kazakh",
//             "code": "kk"
//         },
//         {
//             "language": "Khmer",
//             "code": "km"
//         },
//         {
//             "language": "Korean",
//             "code": "ko"
//         },
//         {
//             "language": "Lao",
//             "code": "lo"
//         },
//         {
//             "language": "Latvian",
//             "code": "lv"
//         },
//         {
//             "language": "Lithuanian",
//             "code": "lt"
//         },
//         {
//             "language": "Macedonian",
//             "code": "mk"
//         },
//         {
//             "language": "Malay",
//             "code": "ms"
//         },
//         {
//             "language": "Malayalam",
//             "code": "ml"
//         },
//         {
//             "language": "Maltese",
//             "code": "mt"
//         },
//         {
//             "language": "Mongolian",
//             "code": "mn"
//         },
//         {
//             "language": "Nepali",
//             "code": "ne"
//         },
//         {
//             "language": "Norwegian",
//             "code": "no"
//         },
//         {
//             "language": "Pashto",
//             "code": "ps"
//         },
//         {
//             "language": "Persian",
//             "code": "fa"
//         },
//         {
//             "language": "Polish",
//             "code": "pl"
//         },
//         {
//             "language": "Portuguese",
//             "code": "pt"
//         },
//         {
//             "language": "Romanian",
//             "code": "ro"
//         },
//         {
//             "language": "Russian",
//             "code": "ru"
//         },
//         {
//             "language": "Serbian",
//             "code": "sr"
//         },
//         {
//             "language": "Sinhala",
//             "code": "si"
//         },
//         {
//             "language": "Slovak",
//             "code": "sk"
//         },
//         {
//             "language": "Slovenian",
//             "code": "sl"
//         },
//         {
//             "language": "Somali",
//             "code": "so"
//         },
//         {
//             "language": "Spanish",
//             "code": "es"
//         },
//         {
//             "language": "Sundanese",
//             "code": "su"
//         },
//         {
//             "language": "Swahili",
//             "code": "sw"
//         },
//         {
//             "language": "Swedish",
//             "code": "sv"
//         },
//         {
//             "language": "Tamil",
//             "code": "ta"
//         },
//         {
//             "language": "Telugu",
//             "code": "te"
//         },
//         {
//             "language": "Thai",
//             "code": "th"
//         },
//         {
//             "language": "Turkish",
//             "code": "tr"
//         },
//         {
//             "language": "Ukrainian",
//             "code": "uk"
//         },
//         {
//             "language": "Urdu",
//             "code": "ur"
//         },
//         {
//             "language": "Uzbek",
//             "code": "uz"
//         },
//         {
//             "language": "Vietnamese",
//             "code": "vi"
//         },
//         {
//             "language": "Welsh",
//             "code": "cy"
//         },
//         {
//             "language": "Zulu",
//             "code": "zu"
//         }
//     ]




//     const [inputValue, setInputValue] = useState('');
//     const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
//     const [staticApiVideo, setStaticApiVideo] = useState('');
//     const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
//     const [messages, setMessages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [videoUrl, setVideoUrl] = useState('');




//     const [audioToText, setAudioToText] = useState('');

//     const [recording, setRecording] = useState(false);
//     const [audioBlob, setAudioBlob] = useState(null);
//     const [timer, setTimer] = useState(0);
//     const [isSoundOn, setIsSoundOn] = useState(true);
//     const [videoLoading, setVideoLoading] = useState(true);
//     const [showChatBot, setShowChatBot] = useState(false);

//     const [recordMessage, setRecordMessage] = useState(false);

//     const [isApiCalled, setIsApiCalled] = useState(false);

//     const videoRef = useRef(null);
//     const videoApiRef = useRef(null);
//     const mediaRecorderRef = useRef(null);
//     const audioChunksRef = useRef([]);
//     const timerRef = useRef(null);
//     const chatHeadRef = useRef(null);
//     const messagesEndRef = useRef(null);

//     const inputRef = useRef(null);

//     const dropdownAvatarRef = useRef(null);
//     const dropdownLanguageRef = useRef(null);

//     const searchInputRef = useRef(null);



//     const [showInitialText, setShowInitialText] = useState(true);
//     const [recentBotIndex, setRecentBotIndex] = useState(null);

//     const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
//     const [checkAudio, setCheckAudio] = useState(false);

//     const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

//     const [showLanguages, setShowLanguages] = useState(false);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredLanguages, setFilteredLanguages] = useState(languages);



//     const [showAvatar, setShowAvatar] = useState(false);
//     const [selectedLanguage, setSelectedLanguage] = useState('English');
//     const [languageCode, setLanguageCode] = useState('en');






//     const [isRecordingTemp, setIsRecordingTemp] = useState(false);
//     const [audioUrlTemp, setAudioUrlTemp] = useState(null);
//     const [apiResponseTemp, setApiResponseTemp] = useState('');
//     const mediaRecorderRefTemp = useRef(null);
//     const recordedChunksRefTemp = useRef([]);
//     const mediaStreamRefTemp = useRef(null);


//     const audioRef = useRef(null);

//     const audioConstraintsTemp = {
//         audio: {
//             sampleRate: 16000,
//             channelCount: 1,
//             echoCancellation: false,
//             noiseSuppression: false,
//             autoGainControl: false,
//         },
//     };




//     // taking params from parent app and passing in iframe api

//     // const [params, setParams] = useState({
//     //   companyName: '',
//     //   moduleName: '',
//     //   avatarName: ''
//     // });

//     // useEffect(() => {
//     //   const receiveMessage = (event) => {

//     //     const { companyName, moduleName, avatarName } = event.data;
//     //     setParams({ companyName, moduleName, avatarName });

//     //   };

//     //   window.addEventListener('message', receiveMessage, false);

//     //   return () => {
//     //     window.removeEventListener('message', receiveMessage);
//     //   };
//     // }, []);





//     // taking params from iframe url only and passing in iframe api


//     const params = useLocation();

//     const pathSegments = params.pathname.split('/').filter(segment => segment);

//     const companyName = pathSegments[pathSegments.length - 3];
//     const moduleName = pathSegments[pathSegments.length - 2];
//     const avatarName = pathSegments[pathSegments.length - 1];








//     const avatarList = [
//         { name: 'Natasha', image: 'image1.png' },
//         // { name: 'Avatar2', image: 'image2.png' },
//     ];




//     const defaultAvatar = avatarList[0];

//     const [selectedAvatar, setSelectedAvatar] = useState({
//         name: defaultAvatar.name,
//         image: defaultAvatar.image,
//     });




//     // user bot states and functions starts


//     const [videoUrlVirtAssist, setVideoUrlVirtAssist] = useState('');
//     const videoRefVirtAssist = useRef(null);


//     const [virAssistCurrentQuestionIndex, virAssistSetCurrentQuestionIndex] = useState(0);
//     const [virAssistCurrentQuestionId, virAssistSetCurrentQuestionId] = useState('id-1');
//     const [virAssistChatHistory, virAssistSetChatHistory] = useState([]);
//     const [virAssistInputValue, virAssistSetInputValue] = useState('');
//     const [virAssistErrorMessage, virAssistSetErrorMessage] = useState('');
//     const [virAssistName, virAssistSetName] = useState('');
//     const [virAssistEmail, virAssistSetEmail] = useState('');
//     const [virAssistPhone, virAssistSetPhone] = useState('');

//     const virAssistCurrentQuestion = chatData.questions[virAssistCurrentQuestionId];


//     const [sessionId, setSessionId] = useState('');


//     const chatRef = useRef(null);



//     useEffect(() => {
//         if (chatRef.current) {
//             chatRef.current.scrollTo({
//                 top: chatRef.current.scrollHeight,
//                 behavior: 'smooth', // Enable smooth scrolling
//             });
//         }
//     }, [virAssistChatHistory, virAssistCurrentQuestionIndex]);



//     if (!virAssistCurrentQuestion) {
//         return <p>Loading...</p>;
//     }

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phoneRegex = /^[0-9]{10}$/;

//     const handleVideoEnd = () => {
//         // Play the static video once the current video ends
//         setVideoUrlVirtAssist(chatData.static_video);
//         if (videoRefVirtAssist.current) {
//             videoRefVirtAssist.current.load();
//             videoRefVirtAssist.current.play();
//         }
//     };

//     useEffect(() => {
//         const currentVideoPath = virAssistCurrentQuestion?.video_path?.[virAssistCurrentQuestionIndex];

//         // Set the video URL to the current video path or fallback to static video
//         if (currentVideoPath) {
//             setVideoUrlVirtAssist(currentVideoPath);
//         } else {
//             setVideoUrlVirtAssist(chatData.static_video);
//         }

//         // Auto-play the video when the URL changes
//         if (videoRefVirtAssist.current) {
//             videoRefVirtAssist.current.load();
//             videoRefVirtAssist.current.play();
//         }
//     }, [virAssistCurrentQuestion, virAssistCurrentQuestionIndex, chatData.static_video]);


//     // Dependencies: when sound state or video URL changes



//     const virAssistHandleResponse = (responseKey) => {
//         const nextQuestionKey = virAssistCurrentQuestion.custompayload[responseKey]?.nextQuestionKey;

//         virAssistSetChatHistory(prevHistory => [
//             ...prevHistory,
//             {
//                 question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex],
//                 response: responseKey
//             }
//         ]);

//         if (virAssistCurrentQuestionIndex < virAssistCurrentQuestion.question.length - 1) {
//             virAssistSetCurrentQuestionIndex(prevIndex => prevIndex + 1);
//         } else {
//             if (nextQuestionKey) {
//                 virAssistSetCurrentQuestionId(nextQuestionKey);
//                 virAssistSetCurrentQuestionIndex(0);
//                 virAssistSetInputValue('');
//                 virAssistSetErrorMessage('');
//             }
//         }
//     };


//     const generateRandomSessionId = () => {
//         return Math.floor(10000000 + Math.random() * 90000000).toString();
//     };

//     useEffect(() => {
//         const randomSessionId = generateRandomSessionId();
//         setSessionId(randomSessionId);
//     }, []);


//     const [inputData, setInputData] = useState('');

//     const virAssistHandleSubmitInput = async () => {

//         const inputFields = virAssistCurrentQuestion.inputFields || [];


//         // if (!virAssistInputValue ||!inputData) {
//         //     virAssistSetErrorMessage('Input cannot be empty.');
//         //     return;
//         // }

//         const matchedResponse = Object.keys(virAssistCurrentQuestion.custompayload || {}).find(
//             (key) => key.toLowerCase() === virAssistInputValue.toLowerCase()
//         );
//         console.log(matchedResponse)



//         // adding feedback api start






//         if (inputFields.includes('feedback_score') && virAssistCurrentQuestionIndex === 0) {
//             let ex = virAssistInputValue
//             try {
//                 const response = await fetch("https://avengine.pranathiss.com/api/save-feedback/", {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         session_id: sessionId,
//                         feedback_score: ex,

//                     }),
//                 });

//                 const data = await response.json();
//                 console.log(chatData.questions[virAssistCurrentQuestionId].custompayload[ex])
//                 console.log(chatData.questions[virAssistCurrentQuestionId].custompayload[ex].api_details)


//                 // const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
//                 // if (nextQuestionKey) {
//                 //     virAssistSetCurrentQuestionId(nextQuestionKey);
//                 //     virAssistSetCurrentQuestionIndex(0);
//                 // }
//             } catch (error) {
//                 console.error('Error:', error);
//                 virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
//             }
//         }

//         // adding feedback api end


//         if (matchedResponse) {
//             virAssistHandleResponse(matchedResponse);
//             return;
//         }


//         if (inputFields.includes('name') && virAssistCurrentQuestionIndex === 0) {
//             virAssistSetName(virAssistInputValue);
//             virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);
//             virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
//         } 
//         else if (inputFields.includes('email') && virAssistCurrentQuestionIndex === 1) {
//             if (!emailRegex.test(virAssistInputValue)) {
//                 virAssistSetErrorMessage('Please enter a valid email address.');
//                 return;
//             }
//             virAssistSetEmail(virAssistInputValue);
//             virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);
//             virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
//         } 
//         else if (inputFields.includes('phone') && virAssistCurrentQuestionIndex === 2) {
//             if (!phoneRegex.test(virAssistInputValue)) {
//                 virAssistSetErrorMessage('Please enter a valid 10-digit phone number.');
//                 return;
//             }
//             virAssistSetPhone(virAssistInputValue);
//             virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);

//             try {
//                 const response = await fetch("https://avengine.pranathiss.com/api/add-lead-response/", {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         session_id: sessionId,
//                         name: virAssistName,
//                         email: virAssistEmail,
//                         phone: virAssistInputValue, // Pass input value directly here
//                     }),
//                 });

//                 const data = await response.json();

//                 const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
//                 if (nextQuestionKey) {
//                     virAssistSetCurrentQuestionId(nextQuestionKey);
//                     virAssistSetCurrentQuestionIndex(0);
//                 }
//             } catch (error) {
//                 console.error('Error:', error);
//                 virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
//             }
//         }



//         // adding existing customer
//         else if (inputFields.includes('email') && virAssistCurrentQuestionIndex === 0) {
//             let ex = virAssistInputValue
//             let temp = [...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]
//             virAssistSetChatHistory(temp);
//             try {
//                 const response = await fetch("https://avengine.pranathiss.com/api/check-existing-customer/", {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         session_id: sessionId,
//                         email: virAssistInputValue,

//                     }),
//                 });

//                 const data = await response.json();

//                 console.log(data)
//                 if(data.code == 202){
//                     virAssistSetChatHistory([...temp, { question: data.message,  }]) 
//                 }else if(data.code == 200){
//                     virAssistSetChatHistory([...temp, { question: data.message,  }]) 
//                 }

//                 // if (nextQuestionKey) {
//                 //     virAssistSetCurrentQuestionId(nextQuestionKey);
//                 //     virAssistSetCurrentQuestionIndex(0);
//                 // }

//             } catch (error) {
//                 console.error('Error:', error);
//                 virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
//             }
//         }








//         else if (inputFields.includes('datetime') && virAssistCurrentQuestionIndex === 0) {
//             const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
//             const timeRegex = /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // HH:MM:SS format

//             // Split input value into date and time parts
//             const [enteredDate, enteredTime] = virAssistInputValue.split(' ');

//             if (!dateRegex.test(enteredDate)) {
//                 virAssistSetErrorMessage('Please enter a valid date in the format YYYY-MM-DD.');
//                 return;
//             }

//             if (!timeRegex.test(enteredTime)) {
//                 virAssistSetErrorMessage('Please enter a valid time in the format HH:MM:SS.');
//                 return;
//             }

//             // No errors, proceed to set the values
//             virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);

//             try {
//                 const response = await fetch("https://avengine.pranathiss.com/api/schedule-meeting/", {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         session_id: sessionId,
//                         date: enteredDate,
//                         time: enteredTime,
//                     }),
//                 });

//                 const data = await response.json();

//                 const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
//                 if (nextQuestionKey) {
//                     virAssistSetCurrentQuestionId(nextQuestionKey);
//                     virAssistSetCurrentQuestionIndex(0); // Reset question index for the new question
//                 }
//             } catch (error) {
//                 console.error('Error:', error);
//                 virAssistSetErrorMessage('Failed to schedule the meeting. Please try again later.');
//             }
//         }
























//         // adding date time api end





//         // adding email for feedback start



//         else if (inputFields.includes('email') && virAssistCurrentQuestionIndex === 0) {
//             let ex = virAssistInputValue
//             virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);
//             try {
//                 const response = await fetch("https://avengine.pranathiss.com/api/add-lead-response/", {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         session_id: sessionId,
//                         email: virAssistInputValue,

//                     }),
//                 });

//                 const data = await response.json();
//                 const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;


//                 if (nextQuestionKey) {
//                     virAssistSetCurrentQuestionId(nextQuestionKey);
//                     virAssistSetCurrentQuestionIndex(0);
//                 }

//             } catch (error) {
//                 console.error('Error:', error);
//                 virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
//             }
//         }





//         // adding email for feedback end



//         //adding description start


//         else if (inputFields.includes('desc') && virAssistCurrentQuestionIndex === 0) {            
//             // Add the user's input to the chat history
//             virAssistSetChatHistory([...virAssistChatHistory, { 
//                 question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], 
//                 response: virAssistInputValue 
//             }]);

//             try {
//                 const response = await fetch("https://avengine.pranathiss.com/api/create-ticket/", {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({
//                         session_id: sessionId,
//                         ticket_desc: virAssistInputValue,
//                     }),
//                 });

//                 const data = await response.json();

//                 // Add the API response message to the chat history
//                 virAssistSetChatHistory(prevChatHistory => [
//                     ...prevChatHistory, 
//                     {
//                         question: 'API Response:', // Label the response as 'API Response' or something else
//                         response: data.message // Display the message from the API response
//                     }
//                 ]);

//                 const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;

//                 if (nextQuestionKey) {
//                     virAssistSetCurrentQuestionId(nextQuestionKey);
//                     virAssistSetCurrentQuestionIndex(0);
//                 }

//             } catch (error) {
//                 console.error('Error:', error);
//                 virAssistSetErrorMessage('Failed to submit your information. Please try again later.');

//                 // Optionally, show the error message in the chat
//                 virAssistSetChatHistory(prevChatHistory => [
//                     ...prevChatHistory, 
//                     {
//                         question: 'Error:', 
//                         response: 'Failed to submit your information. Please try again later.'
//                     }
//                 ]);
//             }
//         }




//         //adding description end












//         else {
//             virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]); // Append to chat

//             const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
//             if (nextQuestionKey) {
//                 virAssistSetCurrentQuestionId(nextQuestionKey);
//                 virAssistSetCurrentQuestionIndex(0);
//             }
//         }

//         virAssistSetInputValue('');
//         virAssistSetErrorMessage('');
//     };


//     const virAssistHandleKeyDown = (e) => {
//         if (e.key === 'Enter') {
//             virAssistHandleSubmitInput();
//         }
//     };

//     // user bot states and functions ends


//     useEffect(() => {

//         if (avatarName) {
//             const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());

//             if (foundAvatar) {
//                 setSelectedAvatar({
//                     name: foundAvatar.name,
//                     image: foundAvatar.image,
//                 });
//             } else {
//                 setSelectedAvatar(defaultAvatar);
//             }
//         } else {
//             setSelectedAvatar(defaultAvatar);
//         }
//     }, [avatarName]);



//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
//                 setShowAvatar(false);
//             }
//         };

//         if (showAvatar) {
//             document.addEventListener('mousedown', handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [showAvatar]);





//     const handleAvatarToggle = () => {
//         setShowAvatar(!showAvatar);
//     };

//     const handleLanguageToggle = () => {
//         setShowLanguages(!showLanguages);
//         if (!showLanguages) {
//             setFilteredLanguages(languages);
//             setSearchTerm('');
//         }
//     };

//     const handleLanguageSelect = (language) => {
//         setSelectedLanguage(language.language);
//         setSearchTerm('');
//         setFilteredLanguages(languages);
//         setShowLanguages(false);
//         setLanguageCode(language.code)
//     };


//     const handleSearchChange = (event) => {
//         const searchTerm = event.target.value.toLowerCase();
//         setSearchTerm(searchTerm);
//         const filtered = languages.filter((language) =>
//             language.language.toLowerCase().startsWith(searchTerm)
//         );
//         setFilteredLanguages(filtered);
//     };



//     useEffect(() => {
//         if (showLanguages) {
//             searchInputRef.current.focus();
//         }
//     }, [showLanguages]);


//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownLanguageRef.current && !dropdownLanguageRef.current.contains(event.target)) {
//                 setShowLanguages(false);
//             }
//         };

//         if (showLanguages) {
//             document.addEventListener('mousedown', handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [showLanguages]);

//     const handleKeyDownSearch = (event) => {
//         if (event.key === 'Enter' && filteredLanguages.length > 0) {
//             handleLanguageSelect(filteredLanguages[0]);
//         }
//     };


//     const handleAvatarSelect = (avatar) => {
//         setSelectedAvatar(avatar);
//         setShowAvatar(false);
//     };



//     useEffect(() => {

//         const showTimeout = setTimeout(() => {
//             setShowWelcomeMsg(true);
//         }, 1);

//         const hideTimeout = setTimeout(() => {
//             setShowWelcomeMsg(false);
//         }, 3000);

//         return () => {
//             clearTimeout(showTimeout);
//             clearTimeout(hideTimeout);
//         };
//     }, []);

//     useEffect(() => {
//         const callApiOnPageLoad = async () => {
//             const payload = { user_input: 'hi' };

//             try {
//                 const response = await fetch(
//                     `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`,
//                     {
//                         method: 'POST',
//                         headers: {
//                             'Content-Type': 'application/json',
//                         },
//                         body: JSON.stringify(payload),
//                     }
//                 );

//                 if (response.ok) {
//                     const data = await response.json();
//                     setWelcomeApiMsg(data.responses[0])
//                     setStaticApiVideo(data.static_video)
//                     setWelcomeApiVideo(data.video_path)
//                 } else {
//                     console.error('API error:', response.statusText);
//                 }
//             } catch (error) {
//                 console.error('Fetch error:', error);
//             }
//         };

//         callApiOnPageLoad();
//     }, [companyName, moduleName, selectedAvatar]);





//     useEffect(() => {
//         const latestBotIndex = messages
//             .map((item, index) => (item.type === 'bot' ? index : null))
//             .filter(index => index !== null)
//             .pop();

//         if (latestBotIndex !== null) {
//             setRecentBotIndex(latestBotIndex);
//             setShowInitialText(true);

//         }
//     }, [messages]);




//     const handleSendMessage = async () => {
//         setInputValue('');

//         if (inputValue.trim() || audioToText) {
//             setLoading(true);
//             setIsApiCalled(true);

//             const userMessage = {
//                 message: audioToText || inputValue.trim() || '',
//                 type: 'user',
//                 audioUrl: audioToText,
//                 isActive: true,
//             };

//             setMessages((prevMessages) => [...prevMessages, userMessage]);

//             const payload = {
//                 user_input: audioToText ? audioToText : inputValue.trim() || '',
//                 lang: languageCode,
//                 companyName: companyName,
//                 moduleName: moduleName,
//                 avatarName: selectedAvatar.name,
//             };

//             if (messagesEndRef.current) {
//                 messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//             }

//             try {
//                 const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(payload),
//                 });

//                 const data = await response.json();

//                 setAudioToText('');
//                 setAudioBlob(null);
//                 setTimer(0);

//                 handleResponseChat(data);

//                 if (messagesEndRef.current) {
//                     messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//                 }

//             } catch (error) {
//                 console.error('Error:', error);
//             } finally {
//                 setLoading(false);
//             }
//         }
//     };




//     const handleResponseChat = (data) => {
//         if (data.responses && data.responses.length > 0) {
//             const botMessage = data.responses.join(' ');
//             setMessages((prevMessages) => [
//                 ...prevMessages,
//                 { message: botMessage, type: 'bot' },
//             ]);
//         } else {
//             setMessages((prevMessages) => [
//                 ...prevMessages,
//                 { message: "Could you please repeat?", type: 'bot' },
//             ]);
//         }

//         if (data.video_path) {
//             const fullVideoUrl = data.video_path;
//             setVideoUrl(fullVideoUrl);


//             if (audioRef.current) {
//                 audioRef.current.pause();
//                 audioRef.current.currentTime = 0;
//             }
//             if (languageCode == 'en') {
//                 if (videoRef.current) {
//                     videoRef.current.src = fullVideoUrl;
//                     videoRef.current.play();

//                     videoRef.current.removeEventListener('ended', playStaticVideo);
//                     videoRef.current.addEventListener('ended', playStaticVideo);
//                 }
//             } else {
//                 if (videoRef.current) {
//                     videoRef.current.src = fullVideoUrl;
//                     videoRef.current.muted = true;
//                     videoRef.current.play();

//                     videoRef.current.removeEventListener('ended', playStaticVideo);
//                     videoRef.current.addEventListener('ended', playStaticVideo);
//                 }

//                 if (data.audio_data) {

//                     const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3');
//                     const audioUrl = URL.createObjectURL(audioBlob);
//                     const audioElement = new Audio(audioUrl);
//                     audioRef.current = audioElement;
//                     audioElement.muted = !isSoundOn;
//                     audioElement.play();

//                     audioElement.addEventListener('ended', () => {
//                         if (videoRef.current) {
//                             videoRef.current.pause();
//                             playStaticVideo();
//                         }
//                     });
//                 }
//             }
//         } else {
//             setVideoUrl('');
//         }
//     };


//     const toggleSound = () => {
//         setIsSoundOn((prevIsSoundOn) => {
//             const newIsSoundOn = !prevIsSoundOn;

//             if (videoRefVirtAssist.current) {
//                 videoRefVirtAssist.current.muted = !newIsSoundOn; // Toggle the video sound
//             }

//             return newIsSoundOn;
//         });
//     };



//     const playStaticVideo = () => {
//         if (videoRef.current) {
//             videoRef.current.src = staticApiVideo;
//             videoRef.current.muted = true;
//             videoRef.current.play();
//         }
//     };




//     const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
//         const byteCharacters = atob(base64Data);
//         const byteArrays = [];

//         for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//             const slice = byteCharacters.slice(offset, offset + sliceSize);

//             const byteNumbers = new Array(slice.length);
//             for (let i = 0; i < slice.length; i++) {
//                 byteNumbers[i] = slice.charCodeAt(i);
//             }

//             const byteArray = new Uint8Array(byteNumbers);
//             byteArrays.push(byteArray);
//         }

//         return new Blob(byteArrays, { type: contentType });
//     };

//     useEffect(() => {
//         if (videoUrl) {
//             setVideoLoading(true);
//             const video = videoRef.current;
//             if (video) {
//                 video.onloadeddata = () => {
//                     setVideoLoading(false);

//                     video.onended = () => {
//                         setVideoUrl('');
//                     };
//                 };
//             }

//         }
//     }, [videoUrl]);



//     useEffect(() => {
//         if (audioBlob) {
//             sendAudioToApi(audioBlob);
//         }
//     }, [audioBlob]);


//     useEffect(() => {
//         const timer = setTimeout(() => {

//             setRecordMessage(false);
//         }, 3000);

//         return () => clearTimeout(timer);
//     }, [recordMessage]);




//     const handleChatBotOpen = () => {
//         setShowChatBot(!showChatBot);
//     };

//     useEffect(() => {
//         setShowChatBot(true);
//     }, []);


//     useEffect(() => {
//         handleSendMessage()
//     }, [audioToText])




//     useEffect(() => {
//         if (messagesEndRef.current) {
//             setTimeout(() => {
//                 if (messagesEndRef.current) {
//                     messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//                 }
//             }, 500);
//         }
//     }, [messages, showChatBot]);






//     const sendAudioToApi = async () => {
//         if (!isRecordingTemp) {
//             try {
//                 // Request microphone access
//                 mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);

//                 const mimeType = isIOS() || isSafari() ? 'audio/mp4' : 'audio/webm';

//                 if (!MediaRecorder.isTypeSupported(mimeType)) {
//                     console.warn(`${mimeType} is not supported on this browser.`);
//                     return;
//                 }

//                 mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });

//                 mediaRecorderRefTemp.current.ondataavailable = (event) => {
//                     if (event.data.size > 0) {
//                         recordedChunksRefTemp.current.push(event.data);
//                     }
//                 };

//                 mediaRecorderRefTemp.current.onstop = async () => {
//                     const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
//                     recordedChunksRefTemp.current = [];

//                     const readerTemp = new FileReader();
//                     readerTemp.readAsDataURL(blobTemp);
//                     readerTemp.onloadend = async () => {
//                         const base64AudioTemp = readerTemp.result.split(',')[1];

//                         const formDataTemp = new FormData();
//                         formDataTemp.append('audio_file', base64AudioTemp);
//                         formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');

//                         try {
//                             const responseTemp = await fetch(`https://speech.pranathiss.com/api/vad-base64-speech-to-text/${selectedLanguage}/`, {
//                                 method: 'POST',
//                                 body: formDataTemp,
//                             });

//                             const resultTemp = await responseTemp.json();
//                             const transcribedText = resultTemp.response.trim();

//                             setInputData(transcribedText)

//                             const inputFields = virAssistCurrentQuestion.inputFields || [];
//                             const matchedResponse = Object.keys(virAssistCurrentQuestion.custompayload || {}).find(
//                                 (key) => key.toLowerCase() === transcribedText.toLowerCase()
//                             );

//                             if (matchedResponse) {
//                                 virAssistHandleResponse(matchedResponse);
//                                 return;
//                             }

//                             if (inputFields.includes('name') && virAssistCurrentQuestionIndex === 0) {
//                                 virAssistSetName(transcribedText);
//                                 virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat
//                                 virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
//                             } else if (inputFields.includes('email') && virAssistCurrentQuestionIndex === 1) {
//                                 if (!emailRegex.test(transcribedText)) {
//                                     virAssistSetErrorMessage('Please enter a valid email address.');
//                                     return;
//                                 }
//                                 virAssistSetEmail(transcribedText);
//                                 virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat
//                                 virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
//                             } else if (inputFields.includes('phone') && virAssistCurrentQuestionIndex === 2) {
//                                 // if (!phoneRegex.test(transcribedText)) {
//                                 //     virAssistSetErrorMessage('Please enter a valid 10-digit phone number.');
//                                 //     return;
//                                 // }
//                                 virAssistSetPhone(transcribedText); // Store the phone number
//                                 virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat

//                                 try {
//                                     const response = await fetch("https://avengine.pranathiss.com/api/add-lead-response/", {
//                                         method: 'POST',
//                                         headers: {
//                                             'Content-Type': 'application/json',
//                                         },
//                                         body: JSON.stringify({
//                                             session_id: sessionId, // Send session_id here
//                                             name: virAssistName,
//                                             email: virAssistEmail,
//                                             phone: virAssistPhone,
//                                         }),
//                                     });

//                                     const data = await response.json();

//                                     const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
//                                     if (nextQuestionKey) {
//                                         virAssistSetCurrentQuestionId(nextQuestionKey);
//                                         virAssistSetCurrentQuestionIndex(0);
//                                     }
//                                 } catch (error) {
//                                     console.error('Error:', error);
//                                     virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
//                                 }
//                             } else {
//                                 virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat

//                                 const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
//                                 if (nextQuestionKey) {
//                                     virAssistSetCurrentQuestionId(nextQuestionKey);
//                                     virAssistSetCurrentQuestionIndex(0);
//                                 }
//                             }

//                             virAssistSetInputValue('');
//                             virAssistSetErrorMessage('');



//                             setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
//                             setAudioToText(transcribedText);

//                             setMessagesVirtAssist(prev => [
//                                 ...prev,
//                                 { user: true, text: transcribedText }
//                             ]);

//                             const matchedOption = Object.values(currentQuestion.customPayload || {}).find(option => {
//                                 const optionResponse = Array.isArray(option.response) ? option.response[0] : option.response;
//                                 return typeof optionResponse === "string" && optionResponse.toLowerCase() === transcribedText.toLowerCase();
//                             });

//                             if (matchedOption) {
//                                 setFollowUpIndex(0);
//                                 setCurrentQuestionId(matchedOption.nextQuestionId);
//                             } else {
//                                 // Optionally, show a fallback message when no match is found
//                                 // setMessagesVirtAssist(prev => [
//                                 //   ...prev,
//                                 //   { user: false, text: "Sorry, I didn't understand that. Please provide valid input." }
//                                 // ]);
//                             }

//                             if (transcribedText.length > 0) {
//                                 setCheckAudio(false);
//                             }

//                         } catch (error) {
//                             console.error('Error calling the API:', error);
//                             setApiResponseTemp(`Error: ${error.message}`);
//                         }
//                     };
//                 };

//                 mediaRecorderRefTemp.current.start();
//                 setIsRecordingTemp(true);
//             } catch (error) {
//                 console.error('Error accessing microphone:', error);
//                 alert('An error occurred while trying to access your microphone. Please try again.');
//                 if (error.name === 'NotAllowedError') {
//                     alert('Microphone access was denied. Please enable it in your settings.');
//                 } else if (error.name === 'NotFoundError') {
//                     alert('No microphone found. Please connect a microphone.');
//                 } else {
//                     alert(`An unexpected error occurred: ${error.message}`);
//                 }
//             }
//         } else {
//             mediaRecorderRefTemp.current.stop();
//             mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
//             setIsRecordingTemp(false);
//         }
//     };


//     const isSafari = () => {
//         const ua = navigator.userAgent.toLowerCase();
//         return ua.includes('safari') && !ua.includes('chrome');
//     };

//     const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);


//     const handleInputSearch = (e) => {
//         e.stopPropagation()
//     }


//     return (

//         <>
//             {
//                 !showChatBot ?
//                     <div className="videochartvalue ">
//                         <div className='container' id='ChatBotContainer'>

//                             <div className='headAndOutput'>

//                                 <div className='chatHead'>
//                                     <div className='headIcontext'>
//                                         <div className='group-2'></div>
//                                         <div className='iconHeadChat'>
//                                             <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
//                                             <div className='headTextClass'>
//                                                 <div className="text-wrapper miniHead">Chat with</div>
//                                                 <div className="text-wrapper-2">AI Assistant</div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className='headMinClose'>

//                                         <div className='languagesDivBoth'>
//                                             <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
//                                                 <div className='avatarNameImgClass'>
//                                                     {selectedAvatar.name}

//                                                 </div>
//                                                 <span className={`arrow ${showAvatar ? 'open' : ''}`}>
//                                                     <img src={arrowDown} alt="" className='downArrowClass' />
//                                                 </span>
//                                                 {showAvatar && (
//                                                     <ul className='languageDropdown'>
//                                                         {avatarList
//                                                             .filter(avatar => avatar !== selectedAvatar)
//                                                             .map((avatar, index) => (
//                                                                 <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>

//                                                                     {avatar.name}
//                                                                 </li>
//                                                             ))}
//                                                     </ul>
//                                                 )}
//                                             </div>


//                                             <div ref={dropdownLanguageRef} className='languagesDiv' onClick={handleLanguageToggle}>

//                                                 {selectedLanguage}

//                                                 <span className={`arrow ${showLanguages ? 'open' : ''}`}>
//                                                     <img src={arrowDown} alt="" className='downArrowClass' />
//                                                 </span>

//                                                 {showLanguages && (
//                                                     <ul className='languageDropdown'>

//                                                         <input
//                                                             ref={searchInputRef}
//                                                             type="text"
//                                                             value={searchTerm}
//                                                             onChange={handleSearchChange}
//                                                             onKeyDown={handleKeyDownSearch}
//                                                             onClick={handleInputSearch}
//                                                             placeholder="Search"
//                                                             className='searchLanguageInput'
//                                                         />
//                                                     </ul>
//                                                 )}
//                                                 {showLanguages && (
//                                                     <ul className='languageDropdown languageSearchDropdown'>

//                                                         {filteredLanguages.map((language, index) => (
//                                                             <li key={index} onClick={() => handleLanguageSelect(language)} className='languageOption'>
//                                                                 <p>

//                                                                     {language.language}
//                                                                 </p>
//                                                             </li>
//                                                         ))}
//                                                     </ul>
//                                                 )}
//                                             </div>




//                                         </div>

//                                         <div className=' speakerIconButton' >
//                                             {isSoundOn ? (
//                                                 <img src={chatSoundIconWhite} alt="Speaker" onClick={toggleSound} />
//                                             ) : (
//                                                 <img src={chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
//                                             )}
//                                             <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
//                                         </div>


//                                     </div>
//                                 </div>



//                                 <div id="output-container">


//                                     {/* user bot main div starts  */}
//                                     <div className='chatMainClass' ref={chatRef}>
//                                        <div className="chat-history" >
//                                              {virAssistChatHistory.map((chat, index) => (
//                                                 <div key={index} className='userBotMsgDiv'>

//                                                    {chat.question ? <div className='botMsgClass'> <img src="/img/chatAvatarIcon3.png" alt="Avatar" /> <p className='botParaClass'> {chat.question}</p></div> : ""}
//                                                    {chat.response ? <div className='userMsgClass'> <p className='userParaClass'> {chat.response}</p> <img src="/img/chatSenderIcon.png" alt="Sender Avatar" /></div> : ""}
//                                                 </div>
//                                             ))}
//                                         </div>
//                                         <div className="current-question " >

//                                             <div className='currentBotMsgClass'>    <img src="/img/chatAvatarIcon3.png" alt="Avatar" /> <p className='currentBotParaClass'> {virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex]}</p></div>

//                                             {virAssistCurrentQuestion.custompayload ? (
//                                                 <div className="response-options">
//                                                     {Object.keys(virAssistCurrentQuestion.custompayload).map((key) => (
//                                                         <button key={key} onClick={() => virAssistHandleResponse(key)}>{key}</button>
//                                                     ))}
//                                                 </div>
//                                             ) : null}


//                                         </div>
//                                     </div>

//                                     {/* user bot main div ends */}



//                                     <div className="hello-container" >

//                                         <div>
//                                             <video
//                                                 ref={videoRefVirtAssist}
//                                                 autoPlay
//                                                 preload="none"
//                                                 onEnded={handleVideoEnd}
//                                                 playsInline
//                                             >
//                                                 <source src={videoUrlVirtAssist} type="video/mp4" />
//                                                 Your browser does not support the video tag.
//                                             </video>

//                                         </div>


//                                     </div>
//                                 </div>
//                             </div>

//                             <div className='outputHelpfulClass'>
//                                 {virAssistErrorMessage && <p className='inputErrorClass'>{virAssistErrorMessage}</p>}
//                                 <div className="input-container">
//                                     <input
//                                         type="text"
//                                         value={virAssistInputValue}
//                                         onChange={(e) => virAssistSetInputValue(e.target.value)}
//                                         onKeyDown={virAssistHandleKeyDown}
//                                         placeholder="Type your response"
//                                     />


//                                     {isRecordingTemp ? (
//                                         <div className="recording-indicator">
//                                             <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
//                                                 <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
//                                             </div>
//                                         </div>
//                                     ) : (
//                                         <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
//                                             <img src="/img/chatMicIcon.png" alt="Mic" />
//                                         </div>
//                                     )}

//                                     <div className='iconButton' onClick={virAssistHandleSubmitInput} disabled={loading || recording}>
//                                         <img src="/img/chatSendIcon.png" alt="Send" />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                     :
//                     <div className='chatBoxImageClass' >
//                         <img src={chatBoxImage} alt="" onClick={handleChatBotOpen} />

//                     </div>
//             }

//         </>

//     );
// };







// export default DirectData;

























import React, { useState, useRef, useEffect } from 'react';
import Picker from 'emoji-picker-react';
import './frameNew.css';
// import './noAudioEnglish.css'

import chatBoxImage from '../../../static/img/chatBoxImageCrop1.png'
import galaGifCircle from '../../../static/img/galaGifCircle.gif'
import arrowDown from '../../../static/img/arrowDownConvert.png'

import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
import chatAvatarIcon3New from '../../../static/img/chatAvatarIcon3New.png'
import { useFetcher, useLocation, useParams } from 'react-router-dom';


const chatData =
{

    "static_video": "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_static_new1.mp4",

    "questions": {

        "id-1": {

            "question": ["Hello and welcome to Varun Digital! How can I help you today?"],

            "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_welcome.mp4"],

            "custompayload": {

                "Talk to Sales": {

                    "nextQuestionKey": "id-2"

                },

                "Customer Support": {

                    "nextQuestionKey": "id-20"

                },

                "Add-on Service or Package Upgrade": {

                    "nextQuestionKey": "id-30"

                }

            }

        },

        "id-2": {

            "question": ["Before we start, Can you please tell me your name?", "Can you please tell me your email?", "Can you please tell me your phone number?"],

            "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_name.mp4", "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_email.mp4", "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_number.mp4"],

            "inputFields": ["name", "email", "phone"],

            "apicall": true,

            "api_details": {

                "url": "https://avengine.pranathiss.com/api/add-lead-response/",

                "method": "POST",

                "message_key": "response",

                "status_key": "status",

                "nextQuestionKey": "id-2"



            },

            "nextQuestionKey": "id-3"

        },

        "id-3": {

            "question": ["Great! We offer a range of digital marketing services including Website Development, SEO, PPC, Social media management, ORM and more. Can you tell me more about what you're looking for?"],

            "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_services.mp4"],

            "nextQuestionKey": "id-4"

        },

        "id-4": {

            "question": ["Got it! Can you provide some details about your business goals and current challenges? This will help us suggest the best solutions for you."],

            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_4.mp4"
            ],

            "fallbackOption": {

                "trigger": ["I don't know", "I do not know"],

                "response": "That's okay! It can be tough to pinpoint exactly what you need. Here are a few questions that might help clarify your goals:\n- Are you looking to increase your online presence or drive more traffic to your site?\n- Do you want to improve your social media engagement or generate more leads?\n- Are there any specific challenges you're facing with your current marketing efforts?",

                "nextQuestionKey": "id-5"

            },

            "nextQuestionKey": "id-5"

        },

        "id-5": {

            "question": ["Perfect! Would you like to schedule a free consultation with one of our experts? We can discuss your needs in more detail and provide a tailored proposal."],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_5.mp4"
            ],
            "custompayload": {

                "Yes": {

                    "nextQuestionKey": "id-6"

                },

                "No": {

                    "nextQuestionKey": "id-10"

                }

            }

        },

        "id-10": {

            "question": ["Great! We offer a range of digital marketing services including Website Development, SEO, PPC, Social media management, ORM and more. Can you tell me more about what you're looking for?"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_10.mp4"
            ],
            "nextQuestionKey": "id-9"

        },

        "id-6": {

            "question": ["Please provide your preferred date and time."],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_6.mp4"
            ],
            "inputFields": ["datetime"],

            "apicall": true,

            "api_details": {

                "url": "https://avengine.pranathiss.com/api/schedule-meeting/",

                "method": "POST",

                "message_key": "response",

                "status_key": "status",

                "nextQuestionKey": "id-6"



            },

            "nextQuestionKey": "id-8"

        },

        "id-8": {

            "question": ["Your live demo has been scheduled with our product specialist. We have sent you an email with the details. Looking forward to e-meeting with you!"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_8.mp4"
            ],
            "nextQuestionKey": "id-9"

        },

        "id-9": {

            "question": ["Rate Your Experience with us. Say Excellent, Good, or Average."],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_9.mp4"
            ],
            "inputFields": ["feedback_score"],

            "custompayload": {

                "Excellent": {

                    "inputFields": ["feedback_score"],

                    "apicall": true,

                    "api_details": {

                        "url": "https://avengine.pranathiss.com/api/save-feedback/",

                        "method": "POST",

                        "message_key": "response",

                        "status_key": "status",

                        "nextQuestionKey": "id-1"

                    },

                    "nextQuestionKey": "id-32"

                },

                "Good": {

                    "apicall": true,

                    "inputFields": ["feedback_score"],

                    "api_details": {

                        "url": "https://avengine.pranathiss.com/api/save-feedback/",

                        "method": "POST",

                        "message_key": "response",

                        "status_key": "status",

                        "nextQuestionKey": "id-1"

                    },

                    "nextQuestionKey": "id-33"

                },

                "Average": {

                    "apicall": true,

                    "inputFields": ["feedback_score"],

                    "api_details": {

                        "url": "https://avengine.pranathiss.com/api/save-feedback/",

                        "method": "POST",

                        "message_key": "response",

                        "status_key": "status",

                        "nextQuestionKey": "id-1"

                    },

                    "nextQuestionKey": "id-34"

                }

            }

        },

        "id-32": {

            "question": ["That's great to hear from you! Thank you for choosing Varun Digital. Have a great day!"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_32.mp4"
            ],
            "nextQuestionKey": "id-1"

        },

        "id-33": {

            "question": ["That's great to hear from you! Thank you for choosing Varun Digital. Have a great day!"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_33.mp4"
            ],
            "nextQuestionKey": "id-1"

        },

        "id-34": {

            "question": ["Thank you for your feedback! We’re always striving to improve and provide the best service possible. We appreciate you choosing Varun Digital. Have a great day!"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_34.mp4"
            ],
            "nextQuestionKey": "id-1"

        },

        "id-20": {

            "question": ["Are you experiencing any issues or need assistance with our services?"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_20.mp4"
            ],
            "custompayload": {

                "Yes": {

                    "nextQuestionKey": "id-21"


                },

                "No": {

                    "nextQuestionKey": "id-1"

                }

            }

        },

        "id-21": {

            "question": ["Could you please provide your email address associated with your account so I can assist you better?"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_21.mp4"
            ],
            "inputFields": ["email"],

            "apicall": true,

            "api_details": {

                "url": "https://avengine.pranathiss.com/api/check-existing-customer/",

                "method": "POST",

                "message_key": "response",

                "status_key": "status",

                "nextQuestionKey": "id-21"



            },

            "nextQuestionKey": "id-22"

        },



        "id-22": {

            "question": ["Could you please elaborate about the problem you are facing?"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_22.mp4"
            ],
            "apicall": true,

            "inputFields": ["desc"],

            "api_details": {

                "url": "https://avengine.pranathiss.com/api/create-ticket/",

                "method": "POST",

                "message_key": "response",

                "status_key": "status",

                "nextQuestionKey": "id-22"



            },

            "nextQuestionKey": "id-23"

        },

        "id-23": {

            "question": ["Thank you. I have raised a ticket and escalated this to our support team who will reach out to you shortly. Is there anything else you need help with right now?"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_23.mp4"
            ],
            "nextQuestionKey": "id-9"

        },

        "id-30": {

            "question": ["We offer various add-on services like video email marketing or additional content creation. Would you like to learn more about any of these services?"],
            "video_path": [
                "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_30.mp4"
            ],
            "nextQuestionKey": "id-35"
        }
        ,

        "id-31": {

            "question": ["No problem at all! If you have any questions or need information about our services, feel free to ask. Alternatively, you can explore our website."],
            "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_31.mp4"],
            "nextQuestionKey": "id-1"

        },
        "id-35": {

            "question": ["Perfect! Would you like to schedule a free consultation with one of our experts? We can discuss your needs in more detail and provide a tailored proposal."],
            "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_white_5.mp4"],
            "custompayload": {

                "Yes": {

                    "nextQuestionKey": "id-36"

                },

                "No": {

                    "nextQuestionKey": "id-31"

                }

            }

        },
        "id-36": {

            "question": ["Before we start, Can you please tell me your name?", "Can you please tell me your email?", "Can you please tell me your phone number?"],

            "video_path": ["https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_name.mp4", "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_email.mp4", "https://dqm77x662xl91.cloudfront.net/vitelcom-static/ai_test/varundigital_new/videos/gala/gala_number.mp4"],

            "inputFields": ["name", "email", "phone"],

            "apicall": true,

            "api_details": {

                "url": "https://avengine.pranathiss.com/api/add-lead-response/",

                "method": "POST",

                "message_key": "response",

                "status_key": "status",

                "nextQuestionKey": "id-36"



            },

            "nextQuestionKey": "id-6"

        }


    }

}









const DirectData = () => {


    const languages = [
        {
            "language": "Afrikaans",
            "code": "af"
        },
        {
            "language": "Albanian",
            "code": "sq"
        },
        {
            "language": "Amharic",
            "code": "am"
        },
        {
            "language": "Arabic",
            "code": "ar"
        },
        {
            "language": "Armenian",
            "code": "hy"
        },
        {
            "language": "Azerbaijani",
            "code": "az"
        },
        {
            "language": "Basque",
            "code": "eu"
        },
        {
            "language": "Bengali",
            "code": "bn"
        },
        {
            "language": "Bosnian",
            "code": "bs"
        },
        {
            "language": "Bulgarian",
            "code": "bg"
        },
        {
            "language": "Catalan",
            "code": "ca"
        },
        {
            "language": "Chinese (Simplified)",
            "code": "zh-CN"
        },
        {
            "language": "Croatian",
            "code": "hr"
        },
        {
            "language": "Czech",
            "code": "cs"
        },
        {
            "language": "Danish",
            "code": "da"
        },
        {
            "language": "Dutch",
            "code": "nl"
        },
        {
            "language": "English",
            "code": "en"
        },
        {
            "language": "Estonian",
            "code": "et"
        },
        {
            "language": "Finnish",
            "code": "fi"
        },
        {
            "language": "French",
            "code": "fr"
        },
        {
            "language": "Galician",
            "code": "gl"
        },
        {
            "language": "Georgian",
            "code": "ka"
        },
        {
            "language": "German",
            "code": "de"
        },
        {
            "language": "Greek",
            "code": "el"
        },
        {
            "language": "Hebrew",
            "code": "iw"
        },
        {
            "language": "Hindi",
            "code": "hi"
        },
        {
            "language": "Hungarian",
            "code": "hu"
        },
        {
            "language": "Icelandic",
            "code": "is"
        },
        {
            "language": "Indonesian",
            "code": "id"
        },
        {
            "language": "Irish",
            "code": "ga"
        },
        {
            "language": "Italian",
            "code": "it"
        },
        {
            "language": "Japanese",
            "code": "ja"
        },
        {
            "language": "Javanese",
            "code": "jw"
        },
        {
            "language": "Kannada",
            "code": "kn"
        },
        {
            "language": "Kazakh",
            "code": "kk"
        },
        {
            "language": "Khmer",
            "code": "km"
        },
        {
            "language": "Korean",
            "code": "ko"
        },
        {
            "language": "Lao",
            "code": "lo"
        },
        {
            "language": "Latvian",
            "code": "lv"
        },
        {
            "language": "Lithuanian",
            "code": "lt"
        },
        {
            "language": "Macedonian",
            "code": "mk"
        },
        {
            "language": "Malay",
            "code": "ms"
        },
        {
            "language": "Malayalam",
            "code": "ml"
        },
        {
            "language": "Maltese",
            "code": "mt"
        },
        {
            "language": "Mongolian",
            "code": "mn"
        },
        {
            "language": "Nepali",
            "code": "ne"
        },
        {
            "language": "Norwegian",
            "code": "no"
        },
        {
            "language": "Pashto",
            "code": "ps"
        },
        {
            "language": "Persian",
            "code": "fa"
        },
        {
            "language": "Polish",
            "code": "pl"
        },
        {
            "language": "Portuguese",
            "code": "pt"
        },
        {
            "language": "Romanian",
            "code": "ro"
        },
        {
            "language": "Russian",
            "code": "ru"
        },
        {
            "language": "Serbian",
            "code": "sr"
        },
        {
            "language": "Sinhala",
            "code": "si"
        },
        {
            "language": "Slovak",
            "code": "sk"
        },
        {
            "language": "Slovenian",
            "code": "sl"
        },
        {
            "language": "Somali",
            "code": "so"
        },
        {
            "language": "Spanish",
            "code": "es"
        },
        {
            "language": "Sundanese",
            "code": "su"
        },
        {
            "language": "Swahili",
            "code": "sw"
        },
        {
            "language": "Swedish",
            "code": "sv"
        },
        {
            "language": "Tamil",
            "code": "ta"
        },
        {
            "language": "Telugu",
            "code": "te"
        },
        {
            "language": "Thai",
            "code": "th"
        },
        {
            "language": "Turkish",
            "code": "tr"
        },
        {
            "language": "Ukrainian",
            "code": "uk"
        },
        {
            "language": "Urdu",
            "code": "ur"
        },
        {
            "language": "Uzbek",
            "code": "uz"
        },
        {
            "language": "Vietnamese",
            "code": "vi"
        },
        {
            "language": "Welsh",
            "code": "cy"
        },
        {
            "language": "Zulu",
            "code": "zu"
        }
    ]




    const [inputValue, setInputValue] = useState('');
    const [welcomeApiMsg, setWelcomeApiMsg] = useState('');
    const [staticApiVideo, setStaticApiVideo] = useState('');
    const [welcomeApiVideo, setWelcomeApiVideo] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');




    const [audioToText, setAudioToText] = useState('');

    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [videoLoading, setVideoLoading] = useState(true);
    const [showChatBot, setShowChatBot] = useState(false);

    const [recordMessage, setRecordMessage] = useState(false);

    const [isApiCalled, setIsApiCalled] = useState(false);

    const videoRef = useRef(null);
    const videoApiRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const timerRef = useRef(null);
    const chatHeadRef = useRef(null);
    const messagesEndRef = useRef(null);

    const inputRef = useRef(null);

    const dropdownAvatarRef = useRef(null);
    const dropdownLanguageRef = useRef(null);

    const searchInputRef = useRef(null);



    const [showInitialText, setShowInitialText] = useState(true);
    const [recentBotIndex, setRecentBotIndex] = useState(null);

    const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
    const [checkAudio, setCheckAudio] = useState(false);

    const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

    const [showLanguages, setShowLanguages] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredLanguages, setFilteredLanguages] = useState(languages);



    const [showAvatar, setShowAvatar] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [languageCode, setLanguageCode] = useState('en');






    const [isRecordingTemp, setIsRecordingTemp] = useState(false);
    const [audioUrlTemp, setAudioUrlTemp] = useState(null);
    const [apiResponseTemp, setApiResponseTemp] = useState('');
    const mediaRecorderRefTemp = useRef(null);
    const recordedChunksRefTemp = useRef([]);
    const mediaStreamRefTemp = useRef(null);


    const audioRef = useRef(null);

    const audioConstraintsTemp = {
        audio: {
            sampleRate: 16000,
            channelCount: 1,
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
        },
    };




    // taking params from parent app and passing in iframe api

    // const [params, setParams] = useState({
    //   companyName: '',
    //   moduleName: '',
    //   avatarName: ''
    // });

    // useEffect(() => {
    //   const receiveMessage = (event) => {

    //     const { companyName, moduleName, avatarName } = event.data;
    //     setParams({ companyName, moduleName, avatarName });

    //   };

    //   window.addEventListener('message', receiveMessage, false);

    //   return () => {
    //     window.removeEventListener('message', receiveMessage);
    //   };
    // }, []);





    // taking params from iframe url only and passing in iframe api


    const params = useLocation();

    const pathSegments = params.pathname.split('/').filter(segment => segment);

    const companyName = pathSegments[pathSegments.length - 3];
    const moduleName = pathSegments[pathSegments.length - 2];
    const avatarName = pathSegments[pathSegments.length - 1];








    const avatarList = [
        { name: 'Natasha', image: 'image1.png' },
        // { name: 'Avatar2', image: 'image2.png' },
    ];




    const defaultAvatar = avatarList[0];

    const [selectedAvatar, setSelectedAvatar] = useState({
        name: defaultAvatar.name,
        image: defaultAvatar.image,
    });




    // user bot states and functions starts


    const [videoUrlVirtAssist, setVideoUrlVirtAssist] = useState('');
    const videoRefVirtAssist = useRef(null);


    const [virAssistCurrentQuestionIndex, virAssistSetCurrentQuestionIndex] = useState(0);
    const [virAssistCurrentQuestionId, virAssistSetCurrentQuestionId] = useState('id-1');
    const [virAssistChatHistory, virAssistSetChatHistory] = useState([]);
    const [virAssistInputValue, virAssistSetInputValue] = useState('');
    const [virAssistErrorMessage, virAssistSetErrorMessage] = useState('');
    const [virAssistName, virAssistSetName] = useState('');
    const [virAssistEmail, virAssistSetEmail] = useState('');
    const [virAssistPhone, virAssistSetPhone] = useState('');

    const virAssistCurrentQuestion = chatData.questions[virAssistCurrentQuestionId];


    const [sessionId, setSessionId] = useState('');


    const chatRef = useRef(null);



    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight,
                behavior: 'smooth', // Enable smooth scrolling
            });
        }
    }, [virAssistChatHistory, virAssistCurrentQuestionIndex]);



    if (!virAssistCurrentQuestion) {
        return <p>Loading...</p>;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    const handleVideoEnd = () => {
        if (videoRefVirtAssist.current) {
            videoRefVirtAssist.current.src = chatData.static_video
            videoRefVirtAssist.current.load();
            videoRefVirtAssist.current.play();
        }
    };

    useEffect(() => {
        // Auto-play the video when the URL changes

    }, []);

    useEffect(() => {
        const currentVideoPath = virAssistCurrentQuestion?.video_path?.[virAssistCurrentQuestionIndex];

        if (videoRefVirtAssist.current) {
            videoRefVirtAssist.current.src = currentVideoPath
            videoRefVirtAssist.current.load();
            videoRefVirtAssist.current.play();
        }
    }, [virAssistCurrentQuestion, virAssistCurrentQuestionIndex, chatData.static_video])

    // Dependencies: when sound state or video URL changes



    const virAssistHandleResponse = (responseKey) => {

        const nextQuestionKey = virAssistCurrentQuestion.custompayload[responseKey]?.nextQuestionKey;

        if (nextQuestionKey) {
            virAssistSetCurrentQuestionId(nextQuestionKey);
            virAssistSetCurrentQuestionIndex(0);
        }


        virAssistSetChatHistory(prevHistory => [
            ...prevHistory,
            {
                question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex],
                response: responseKey
            }
        ]);

        if (virAssistCurrentQuestionIndex < virAssistCurrentQuestion.question.length - 1) {
            virAssistSetCurrentQuestionIndex(prevIndex => prevIndex + 1);
        } else {
            if (nextQuestionKey) {
                virAssistSetCurrentQuestionId(nextQuestionKey);
                virAssistSetCurrentQuestionIndex(0);
                virAssistSetInputValue('');
                virAssistSetErrorMessage('');
            }
        }
        virAssistSetErrorMessage('');
    };


    const generateRandomSessionId = () => {
        return Math.floor(10000000 + Math.random() * 90000000).toString();
    };

    useEffect(() => {
        const randomSessionId = generateRandomSessionId();
        setSessionId(randomSessionId);
    }, []);


    const [inputData, setInputData] = useState('');

    const virAssistHandleSubmitInput = async (input) => {



        const inputFields = virAssistCurrentQuestion.inputFields || [];

        // Check if input matches any keys in custompayload
        const matchedResponse = Object.keys(virAssistCurrentQuestion.custompayload || {}).find(
            (key) => key.toLowerCase() === virAssistInputValue.toLowerCase()
        );

        if (matchedResponse) {
            const nextQuestionKey = virAssistCurrentQuestion.custompayload[matchedResponse]?.nextQuestionKey;

            if (nextQuestionKey) {
                virAssistSetCurrentQuestionId(nextQuestionKey);
                virAssistSetCurrentQuestionIndex(0); // Reset for the new question
                virAssistSetChatHistory([
                    ...virAssistChatHistory,
                    { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }
                ]);
                virAssistSetInputValue('');
                return; // Stop further execution if matched
            }

        }

        // Example of additional logic for input fields validation
        if (inputFields.includes('name') && virAssistCurrentQuestionIndex === 0) {
            virAssistSetName(virAssistInputValue);
            virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);
            virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
        } else if (inputFields.includes('email') && virAssistCurrentQuestionIndex === 1) {
            if (!emailRegex.test(virAssistInputValue)) {
                virAssistSetErrorMessage('Please enter a valid email address.');
                return;
            }
            virAssistSetEmail(virAssistInputValue);
            virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);
            virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
        } else if (inputFields.includes('phone') && virAssistCurrentQuestionIndex === 2) {
            if (!phoneRegex.test(virAssistInputValue)) {
                virAssistSetErrorMessage('Please enter a valid 10-digit phone number.');
                return;
            }
            virAssistSetPhone(virAssistInputValue);
            virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);

            try {
                const response = await fetch("https://avengine.pranathiss.com/api/add-lead-response/", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        session_id: sessionId,
                        name: virAssistName,
                        email: virAssistEmail,
                        phone: virAssistInputValue,
                    }),
                });

                const data = await response.json();
                const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;

                if (nextQuestionKey) {
                    virAssistSetCurrentQuestionId(nextQuestionKey);
                    virAssistSetCurrentQuestionIndex(0); // Reset index for the new question
                }
            } catch (error) {
                console.error('Error:', error);
                virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
            }
        }



        // else if (inputFields.includes('datetime') && virAssistCurrentQuestionIndex === 0) {
        //     const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
        //     const timeRegex = /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // HH:MM:SS format


        //     let temp = [...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]
        //     virAssistSetChatHistory(temp);




        //     // Split input value into date and time parts
        //     const [enteredDate, enteredTime] = virAssistInputValue.split(' ');

        //     // if (!dateRegex.test(enteredDate)) {
        //     //     virAssistSetErrorMessage('Please enter a valid date in the format YYYY-MM-DD.');
        //     //     return;
        //     // }

        //     // if (!timeRegex.test(enteredTime)) {
        //     //     virAssistSetErrorMessage('Please enter a valid time in the format HH:MM:SS.');
        //     //     return;
        //     // }

        //     // No errors, proceed to set the values
        //     virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);

        //     try {
        //         const response = await fetch("https://avengine.pranathiss.com/api/schedule-meeting/", {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({
        //                 session_id: sessionId,
        //                 // date: enteredDate,
        //                 // time: enteredTime,
        //                 text: virAssistInputValue
        //             }),
        //         });

        //         const data = await response.json();


        //         if (data.code == 200) {
        //             virAssistSetChatHistory([...temp, { question: `Your live demo has been scheduled with our product specialist on ${enteredDate} at ${enteredTime}. We have sent you an email with the details. Looking forward to e-meeting with you! `, }])
        //             const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
        //             if (nextQuestionKey) {
        //                 virAssistSetCurrentQuestionId(nextQuestionKey);
        //                 virAssistSetCurrentQuestionIndex(0); // Reset question index for the new question
        //             }
        //         } else if(data.code == 400)
        //         {

        //         }
        //         else {
        //             console.log('error of schedule meeting');

        //         }





        //         // const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
        //         // if (nextQuestionKey) {
        //         //     virAssistSetCurrentQuestionId(nextQuestionKey);
        //         //     virAssistSetCurrentQuestionIndex(0); // Reset question index for the new question
        //         // }
        //     } catch (error) {
        //         console.error('Error:', error);
        //         virAssistSetErrorMessage('Failed to schedule the meeting. Please try again later.');
        //     }
        // }




        else if (inputFields.includes('datetime') && virAssistCurrentQuestionIndex === 0) {
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
            const timeRegex = /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // HH:MM:SS format


            let temp = [...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]
            virAssistSetChatHistory(temp);


            const [enteredDate, enteredTime] = virAssistInputValue.split(' ');
            const newDate = Date()
            virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]);
            // if (isFutureDate(virAssistInputValue, newDate)) {


            try {
                const response = await fetch("https://avengine.pranathiss.com/api/schedule-meeting/", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        session_id: sessionId,
                        text: virAssistInputValue
                    }),
                });

                const data = await response.json();



                // if (data.code == 400) {
                //     virAssistSetChatHistory([...temp, { question: `${data.message}`, }])

                // } else if(data.code == 200){

                //     const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
                //     if (nextQuestionKey) {
                //         virAssistSetCurrentQuestionId(nextQuestionKey);
                //         virAssistSetCurrentQuestionIndex(0); // Reset question index for the new question
                //     }
                // }
                // else{
                //     console.log('else for schedule meeting');

                // }

                if (data.code == 200) {

                    const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
                    if (nextQuestionKey) {
                        virAssistSetCurrentQuestionId(nextQuestionKey);
                        virAssistSetCurrentQuestionIndex(0); // Reset question index for the new question
                    }
                } else if (data.code == 400) {
                    console.log('else for schedule meeting');

                    // virAssistSetChatHistory([...temp, { question: `${data.message}`, }])
                }
                else {
                    console.log('else for schedule meeting');

                }





            } catch (error) {
                console.error('Error:', error);
                virAssistSetErrorMessage('Failed to schedule the meeting. Please try again later.');
            }
            // }
            // else {
            //     console.log('not valid date');
            //     virAssistSetErrorMessage('Please enter valid date and time')
            //     return
            // }
        }














        // adding date time api end


        // adding email for existing customer start



        else if (inputFields.includes('email') && virAssistCurrentQuestionIndex === 0) {
            let temp = [...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]
            virAssistSetChatHistory(temp);
            if (!emailRegex.test(virAssistInputValue)) {
                virAssistSetErrorMessage('Please enter a valid email address.');
                return;
            }
            else {
                try {
                    const response = await fetch("https://avengine.pranathiss.com/api/check-existing-customer/", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            session_id: sessionId,
                            email: virAssistInputValue,

                        }),
                    });

                    const data = await response.json();



                    if (!data.flag) {
                        virAssistSetChatHistory([...temp, { question: `${virAssistInputValue}  is not registered.`, }])

                    } else {

                        const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
                        if (nextQuestionKey) {
                            virAssistSetCurrentQuestionId(nextQuestionKey);
                            virAssistSetCurrentQuestionIndex(0); // Reset question index for the new question
                        }
                    }


                } catch (error) {
                    console.error('Error:', error);
                    virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
                }
            }

        }





        // adding email for existing customer end



        //adding description start


        else if (inputFields.includes('desc') && virAssistCurrentQuestionIndex === 0) {



            let temp = [...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]
            virAssistSetChatHistory(temp);


            // Add the user's input to the chat history
            virAssistSetChatHistory([...virAssistChatHistory, {
                question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex],
                response: virAssistInputValue
            }]);

            try {
                const response = await fetch("https://avengine.pranathiss.com/api/create-ticket/", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        session_id: sessionId,
                        ticket_desc: virAssistInputValue,
                    }),
                });

                const data = await response.json();


                if (data.code == 200) {
                    virAssistSetChatHistory([...temp, { question: data.message, }])
                } else if (data.code == 202) {
                    virAssistSetChatHistory([...temp, { question: data.message, }])
                }

                // if (nextQuestionKey) {
                //     virAssistSetCurrentQuestionId(nextQuestionKey);
                //     virAssistSetCurrentQuestionIndex(0);
                // }





                // Add the API response message to the chat history
                // virAssistSetChatHistory(prevChatHistory => [
                //     ...prevChatHistory, 
                //     {
                //         question: 'API Response:', // Label the response as 'API Response' or something else
                //         response: data.message // Display the message from the API response
                //     }
                // ]);

                const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;

                if (nextQuestionKey) {
                    virAssistSetCurrentQuestionId(nextQuestionKey);
                    virAssistSetCurrentQuestionIndex(0);
                }

            } catch (error) {
                console.error('Error:', error);
                virAssistSetErrorMessage('Failed to submit your information. Please try again later.');

                // Optionally, show the error message in the chat
                virAssistSetChatHistory(prevChatHistory => [
                    ...prevChatHistory,
                    {
                        question: 'Error:',
                        response: 'Failed to submit your information. Please try again later.'
                    }
                ]);
            }
        }


        //adding description end







        //adding feedback start



        // else if (inputFields.includes('feedback_score') && virAssistCurrentQuestionIndex === 0) {



        //     let temp = [...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue }]
        //     virAssistSetChatHistory(temp);


        //     // Add the user's input to the chat history
        //     virAssistSetChatHistory([...virAssistChatHistory, {
        //         question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex],
        //         response: virAssistInputValue
        //     }]);

        //     try {
        //         const response = await fetch("https://avengine.pranathiss.com/api/save-feedback/", {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({
        //                 session_id: sessionId,
        //                 feedback_score: virAssistInputValue,
        //             }),
        //         });

        //         const data = await response.json();
        //         virAssistSetChatHistory([...temp, { question: data.message, }])
        //         const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;

        //         if (nextQuestionKey) {
        //             virAssistSetCurrentQuestionId(nextQuestionKey);
        //             virAssistSetCurrentQuestionIndex(0);
        //         }

        //         if (data.status_code == 200) {
        //             virAssistSetChatHistory([...temp, { question: data.message, }])
        //             const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;

        //         if (nextQuestionKey) {
        //             virAssistSetCurrentQuestionId(nextQuestionKey);
        //             virAssistSetCurrentQuestionIndex(0);
        //         }
        //         } else {
        //           const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;

        //           if (nextQuestionKey) {
        //               virAssistSetCurrentQuestionId(nextQuestionKey);
        //               virAssistSetCurrentQuestionIndex(0);
        //           }

        //         }




        //     } catch (error) {
        //         console.error('Error:', error);
        //         virAssistSetErrorMessage('Failed to submit your information. Please try again later.');

        //         // Optionally, show the error message in the chat
        //         virAssistSetChatHistory(prevChatHistory => [
        //             ...prevChatHistory,
        //             {
        //                 question: 'Error:',
        //                 response: 'Failed to submit your information. Please try again later.'
        //             }
        //         ]);
        //     }
        // }
















        //adding feedback end


        else {
            virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: virAssistInputValue ? virAssistInputValue : input }]); // Append to chat

            const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
            if (nextQuestionKey) {
                virAssistSetCurrentQuestionId(nextQuestionKey);
                virAssistSetCurrentQuestionIndex(0);
            }
        }

        virAssistSetInputValue('');
        virAssistSetErrorMessage('');

        virAssistSetInputValue("");



    };


    const virAssistHandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            virAssistHandleSubmitInput();
        }
    };

    // user bot states and functions ends


    useEffect(() => {

        if (avatarName) {
            const foundAvatar = avatarList.find(avatar => avatar.name.toLowerCase() === avatarName.toLowerCase());

            if (foundAvatar) {
                setSelectedAvatar({
                    name: foundAvatar.name,
                    image: foundAvatar.image,
                });
            } else {
                setSelectedAvatar(defaultAvatar);
            }
        } else {
            setSelectedAvatar(defaultAvatar);
        }
    }, [avatarName]);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownAvatarRef.current && !dropdownAvatarRef.current.contains(event.target)) {
                setShowAvatar(false);
            }
        };

        if (showAvatar) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAvatar]);





    const handleAvatarToggle = () => {
        setShowAvatar(!showAvatar);
    };

    const handleLanguageToggle = () => {
        setShowLanguages(!showLanguages);
        if (!showLanguages) {
            setFilteredLanguages(languages);
            setSearchTerm('');
        }
    };

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language.language);
        setSearchTerm('');
        setFilteredLanguages(languages);
        setShowLanguages(false);
        setLanguageCode(language.code)
    };


    const handleSearchChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filtered = languages.filter((language) =>
            language.language.toLowerCase().startsWith(searchTerm)
        );
        setFilteredLanguages(filtered);
    };



    useEffect(() => {
        if (showLanguages) {
            searchInputRef.current.focus();
        }
    }, [showLanguages]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownLanguageRef.current && !dropdownLanguageRef.current.contains(event.target)) {
                setShowLanguages(false);
            }
        };

        if (showLanguages) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showLanguages]);

    const handleKeyDownSearch = (event) => {
        if (event.key === 'Enter' && filteredLanguages.length > 0) {
            handleLanguageSelect(filteredLanguages[0]);
        }
    };


    const handleAvatarSelect = (avatar) => {
        setSelectedAvatar(avatar);
        setShowAvatar(false);
    };



    useEffect(() => {

        const showTimeout = setTimeout(() => {
            setShowWelcomeMsg(true);
        }, 1);

        const hideTimeout = setTimeout(() => {
            setShowWelcomeMsg(false);
        }, 3000);

        return () => {
            clearTimeout(showTimeout);
            clearTimeout(hideTimeout);
        };
    }, []);

    useEffect(() => {
        const callApiOnPageLoad = async () => {
            const payload = { user_input: 'hi' };

            try {
                const response = await fetch(
                    `https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setWelcomeApiMsg(data.responses[0])
                    setStaticApiVideo(data.static_video)
                    setWelcomeApiVideo(data.video_path)
                } else {
                    console.error('API error:', response.statusText);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        callApiOnPageLoad();
    }, [companyName, moduleName, selectedAvatar]);





    useEffect(() => {
        const latestBotIndex = messages
            .map((item, index) => (item.type === 'bot' ? index : null))
            .filter(index => index !== null)
            .pop();

        if (latestBotIndex !== null) {
            setRecentBotIndex(latestBotIndex);
            setShowInitialText(true);

        }
    }, [messages]);




    const handleSendMessage = async () => {
        setInputValue('');

        if (inputValue.trim() || audioToText) {
            setLoading(true);
            setIsApiCalled(true);

            const userMessage = {
                message: audioToText || inputValue.trim() || '',
                type: 'user',
                audioUrl: audioToText,
                isActive: true,
            };

            setMessages((prevMessages) => [...prevMessages, userMessage]);

            const payload = {
                user_input: audioToText ? audioToText : inputValue.trim() || '',
                lang: languageCode,
                companyName: companyName,
                moduleName: moduleName,
                avatarName: selectedAvatar.name,
            };

            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            try {
                const response = await fetch(`https://avengine.pranathiss.com/api/company/${companyName}/${moduleName}/${selectedAvatar.name.toLowerCase()}/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const data = await response.json();

                setAudioToText('');
                setAudioBlob(null);
                setTimer(0);

                handleResponseChat(data);

                if (messagesEndRef.current) {
                    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }

            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        }

    };




    const handleResponseChat = (data) => {
        if (data.responses && data.responses.length > 0) {
            const botMessage = data.responses.join(' ');
            setMessages((prevMessages) => [
                ...prevMessages,
                { message: botMessage, type: 'bot' },
            ]);
        } else {
            setMessages((prevMessages) => [
                ...prevMessages,
                { message: "Could you please repeat?", type: 'bot' },
            ]);
        }

        if (data.video_path) {
            const fullVideoUrl = data.video_path;
            setVideoUrl(fullVideoUrl);


            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            if (languageCode == 'en') {
                if (videoRef.current) {
                    videoRef.current.src = fullVideoUrl;
                    videoRef.current.play();

                    videoRef.current.removeEventListener('ended', playStaticVideo);
                    videoRef.current.addEventListener('ended', playStaticVideo);
                }
            } else {
                if (videoRef.current) {
                    videoRef.current.src = fullVideoUrl;
                    videoRef.current.muted = true;
                    videoRef.current.play();

                    videoRef.current.removeEventListener('ended', playStaticVideo);
                    videoRef.current.addEventListener('ended', playStaticVideo);
                }

                if (data.audio_data) {

                    const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3');
                    const audioUrl = URL.createObjectURL(audioBlob);
                    const audioElement = new Audio(audioUrl);
                    audioRef.current = audioElement;
                    audioElement.muted = !isSoundOn;
                    audioElement.play();

                    audioElement.addEventListener('ended', () => {
                        if (videoRef.current) {
                            videoRef.current.pause();
                            playStaticVideo();
                        }
                    });
                }
            }
        } else {
            setVideoUrl('');
        }
    };


    const toggleSound = () => {
        setIsSoundOn((prevIsSoundOn) => {
            const newIsSoundOn = !prevIsSoundOn;

            if (videoRefVirtAssist.current) {
                videoRefVirtAssist.current.muted = !newIsSoundOn; // Toggle the video sound
            }

            return newIsSoundOn;
        });
    };



    const playStaticVideo = () => {
        if (videoRef.current) {
            videoRef.current.src = staticApiVideo;
            videoRef.current.muted = true;
            videoRef.current.play();
        }
    };




    const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    useEffect(() => {
        if (videoUrl) {
            setVideoLoading(true);
            const video = videoRef.current;
            if (video) {
                video.onloadeddata = () => {
                    setVideoLoading(false);

                    video.onended = () => {
                        setVideoUrl('');
                    };
                };
            }

        }
    }, [videoUrl]);



    useEffect(() => {
        if (audioBlob) {
            sendAudioToApi(audioBlob);
        }
    }, [audioBlob]);


    useEffect(() => {
        const timer = setTimeout(() => {

            setRecordMessage(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [recordMessage]);




    const handleChatBotOpen = () => {
        setShowChatBot(!showChatBot);

        const currentVideoPath = virAssistCurrentQuestion?.video_path?.[virAssistCurrentQuestionIndex];

        setTimeout(() => {
            if (videoRefVirtAssist.current) {
                videoRefVirtAssist.current.src = currentVideoPath
                videoRefVirtAssist.current.load();
                videoRefVirtAssist.current.play();
            }
            if (videoRefVirtAssist.current) {
                videoRefVirtAssist.current.onended = () => {
                    videoRefVirtAssist.current.src = chatData.static_video
                    videoRefVirtAssist.current.load();
                    videoRefVirtAssist.current.play();
                }
            }
        }, 300);
    };

    useEffect(() => {
        setShowChatBot(true);
    }, []);



    useEffect(() => {
        handleSendMessage()
    }, [audioToText])




    useEffect(() => {
        if (messagesEndRef.current) {
            setTimeout(() => {
                if (messagesEndRef.current) {
                    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500);
        }
    }, [messages, showChatBot]);


    function isFutureDate(transcribedText, currentDate) {
        console.log('eeeeeeeeeeeee', transcribedText, currentDate);

        const [day, month, year, time] = transcribedText.split(' ');
        const dateString = `${month} ${day} ${year} ${time}`;
        const transcribedDate = new Date(dateString);

        // Compare dates
        return transcribedDate > currentDate;
    }


    const cleanText = (text) => {
        return text
            .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "") // Removes punctuation marks
            .replace(/\s{2,}/g, " ") // Replaces multiple spaces with a single space
            .trim(); // Removes spaces from the start and end
    };

    const sendAudioToApi = async () => {
        if (!isRecordingTemp) {
            try {
                // Request microphone access
                mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);

                const mimeType = isIOS() || isSafari() ? 'audio/mp4' : 'audio/webm';

                if (!MediaRecorder.isTypeSupported(mimeType)) {
                    console.warn(`${mimeType} is not supported on this browser.`);
                    return;
                }

                mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });

                mediaRecorderRefTemp.current.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        recordedChunksRefTemp.current.push(event.data);
                    }
                };

                mediaRecorderRefTemp.current.onstop = async () => {
                    const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
                    recordedChunksRefTemp.current = [];

                    const readerTemp = new FileReader();
                    readerTemp.readAsDataURL(blobTemp);
                    readerTemp.onloadend = async () => {
                        const base64AudioTemp = readerTemp.result.split(',')[1];

                        const formDataTemp = new FormData();
                        formDataTemp.append('audio_file', base64AudioTemp);
                        formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');

                        try {
                            const responseTemp = await fetch(`https://speech.pranathiss.com/api/vad-base64-speech-to-text/${selectedLanguage}/`, {
                                method: 'POST',
                                body: formDataTemp,
                            });

                            const resultTemp = await responseTemp.json();
                            const transcribedText1 = resultTemp.response.trim();
                            const dateTime = resultTemp.response.trim();
                            // const transcribedText = "Hello, world! How are you doing today?";
                            const transcribedText = cleanText(transcribedText1);

                            console.log('cleanedTextcleanedText', transcribedText);

                            setInputData(transcribedText)

                            const inputFields = virAssistCurrentQuestion.inputFields || [];
                            const matchedResponse = Object.keys(virAssistCurrentQuestion.custompayload || {}).find(
                                (key) => key.toLowerCase() === transcribedText.toLowerCase()
                            );

                            if (matchedResponse) {
                                virAssistHandleResponse(matchedResponse);
                                return;
                            }

                            if (inputFields.includes('name') && virAssistCurrentQuestionIndex === 0) {
                                virAssistSetName(transcribedText);
                                virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat
                                virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
                            } else if (inputFields.includes('email') && virAssistCurrentQuestionIndex === 1) {
                                if (!emailRegex.test(transcribedText)) {
                                    virAssistSetErrorMessage('Please enter a valid email address.');
                                    return;
                                }
                                virAssistSetEmail(transcribedText);
                                virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat
                                virAssistSetCurrentQuestionIndex(virAssistCurrentQuestionIndex + 1);
                            } else if (inputFields.includes('phone') && virAssistCurrentQuestionIndex === 2) {
                                // if (!phoneRegex.test(transcribedText)) {
                                //     virAssistSetErrorMessage('Please enter a valid 10-digit phone number.');
                                //     return;
                                // }
                                virAssistSetPhone(transcribedText); // Store the phone number
                                virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat

                                try {
                                    const response = await fetch("https://avengine.pranathiss.com/api/add-lead-response/", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                            session_id: sessionId, // Send session_id here
                                            name: virAssistName,
                                            email: virAssistEmail,
                                            phone: virAssistPhone,
                                        }),
                                    });

                                    const data = await response.json();

                                    const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
                                    if (nextQuestionKey) {
                                        virAssistSetCurrentQuestionId(nextQuestionKey);
                                        virAssistSetCurrentQuestionIndex(0);
                                    }
                                } catch (error) {
                                    console.error('Error:', error);
                                    virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
                                }
                            }
                            else if (inputFields.includes('datetime') && virAssistCurrentQuestionIndex === 0) {
                                const currentDate = new Date();

                                // Check if transcribedText is a valid future date
                                // if (isFutureDate(transcribedText, currentDate)) {
                                // Valid date, proceed with API call and chat history update
                                virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: dateTime ? dateTime : transcribedText }]);

                                try {
                                    const response = await fetch("https://avengine.pranathiss.com/api/schedule-meeting/", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                            session_id: sessionId,
                                            text: dateTime
                                        }),
                                    });

                                    const data = await response.json();

                                    const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
                                    if (nextQuestionKey) {
                                        virAssistSetCurrentQuestionId(nextQuestionKey);
                                        virAssistSetCurrentQuestionIndex(0);
                                    }
                                } catch (error) {
                                    console.error('Error:', error);
                                    virAssistSetErrorMessage('Failed to submit your information. Please try again later.');
                                }
                                // } 
                                // else {
                                //     // Invalid date, set an error message

                                //     virAssistSetErrorMessage('Please enter valid date and time.');
                                //     console.log('Please enter a date and time in the future.')
                                //     return
                                // }
                            }



                            else {
                                virAssistSetChatHistory([...virAssistChatHistory, { question: virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex], response: transcribedText }]); // Append to chat

                                const nextQuestionKey = virAssistCurrentQuestion.nextQuestionKey;
                                if (nextQuestionKey) {
                                    virAssistSetCurrentQuestionId(nextQuestionKey);
                                    virAssistSetCurrentQuestionIndex(0);
                                }
                            }

                            virAssistSetInputValue('');
                            virAssistSetErrorMessage('');



                            setApiResponseTemp(JSON.stringify(resultTemp, null, 2));
                            setAudioToText(transcribedText);

                            setMessagesVirtAssist(prev => [
                                ...prev,
                                { user: true, text: transcribedText }
                            ]);

                            const matchedOption = Object.values(currentQuestion.customPayload || {}).find(option => {
                                const optionResponse = Array.isArray(option.response) ? option.response[0] : option.response;
                                return typeof optionResponse === "string" && optionResponse.toLowerCase() === transcribedText.toLowerCase();
                            });

                            if (matchedOption) {
                                setFollowUpIndex(0);
                                setCurrentQuestionId(matchedOption.nextQuestionId);
                            } else {
                                // Optionally, show a fallback message when no match is found
                                // setMessagesVirtAssist(prev => [
                                //   ...prev,
                                //   { user: false, text: "Sorry, I didn't understand that. Please provide valid input." }
                                // ]);
                            }

                            if (transcribedText.length > 0) {
                                setCheckAudio(false);
                            }

                        } catch (error) {
                            console.error('Error calling the API:', error);
                            setApiResponseTemp(`Error: ${error.message}`);
                        }
                    };
                };

                mediaRecorderRefTemp.current.start();
                setIsRecordingTemp(true);
            } catch (error) {
                console.error('Error accessing microphone:', error);
                if (error.name === 'NotAllowedError') {
                    // alert('Microphone access was denied. Please enable it in your settings.');
                    console.error('Microphone access was denied. Please enable it in your settings.');
                } else if (error.name === 'NotFoundError') {
                    // alert('No microphone found. Please connect a microphone.');
                    console.error('No microphone found. Please connect a microphone.')
                } else {

                    // alert(`An unexpected error occurred: ${error.message}`);
                    console.error('An unexpected error occurred')
                }
            }
        } else {
            mediaRecorderRefTemp.current.stop();
            mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
            setIsRecordingTemp(false);
        }
    };


    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.includes('safari') && !ua.includes('chrome');
    };

    const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);


    const handleInputSearch = (e) => {
        e.stopPropagation()
    }
    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    return (

        <>
            {
                !showChatBot ?
                    <div className="videochartvalue ">
                        <div className='container' id='ChatBotContainer'>

                            <div className='headAndOutput'>

                                <div className='chatHead'>
                                    <div className='headIcontext'>
                                        <div className='group-2'></div>
                                        <div className='iconHeadChat'>
                                            {/* <img src="/img/chatAvatarIcon3.png" alt="Avatar" /> */}
                                            <img src={chatAvatarIcon3New} alt="Avatar" />
                                            <div className='headTextClass'>
                                                <div className="text-wrapper miniHead">Chat with</div>
                                                <div className="text-wrapper-2">AI Assistant</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='headMinClose'>

                                        <div className='languagesDivBoth'>
                                            <div className='languagesDiv' onClick={handleAvatarToggle} ref={dropdownAvatarRef} >
                                                <div className='avatarNameImgClass'>
                                                    {selectedAvatar.name}

                                                </div>
                                                <span className={`arrow ${showAvatar ? 'open' : ''}`}>
                                                    <img src={arrowDown} alt="" className='downArrowClass' />
                                                </span>
                                                {showAvatar && (
                                                    <ul className='languageDropdown'>
                                                        {avatarList
                                                            .filter(avatar => avatar !== selectedAvatar)
                                                            .map((avatar, index) => (
                                                                <li key={index} onClick={() => handleAvatarSelect(avatar)} className='avatarNameImgClass'>

                                                                    {avatar.name}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                )}
                                            </div>


                                            {/* <div ref={dropdownLanguageRef} className='languagesDiv' onClick={handleLanguageToggle}>

                                                {selectedLanguage}

                                                <span className={`arrow ${showLanguages ? 'open' : ''}`}>
                                                    <img src={arrowDown} alt="" className='downArrowClass' />
                                                </span>

                                                {showLanguages && (
                                                    <ul className='languageDropdown'>

                                                        <input
                                                            ref={searchInputRef}
                                                            type="text"
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                            onKeyDown={handleKeyDownSearch}
                                                            onClick={handleInputSearch}
                                                            placeholder="Search"
                                                            className='searchLanguageInput'
                                                        />
                                                    </ul>
                                                )}
                                                {showLanguages && (
                                                    <ul className='languageDropdown languageSearchDropdown'>

                                                        {filteredLanguages.map((language, index) => (
                                                            <li key={index} onClick={() => handleLanguageSelect(language)} className='languageOption'>
                                                                <p>

                                                                    {language.language}
                                                                </p>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div> */}




                                        </div>

                                        <div className=' speakerIconButton' >
                                            {isSoundOn ? (
                                                <img src={chatSoundIconWhite} alt="Speaker" onClick={toggleSound} />
                                            ) : (
                                                <img src={chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
                                            )}
                                            <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={handleChatBotOpen} />
                                        </div>


                                    </div>
                                </div>



                                <div id="output-container">


                                    {/* user bot main div starts  */}
                                    <div className='chatMainClass' ref={chatRef}>
                                        <div className="chat-history" >
                                            {virAssistChatHistory.map((chat, index) => (
                                                <div key={index} className='userBotMsgDiv'>

                                                    {chat.question ? <div className='botMsgClass'> <img src={chatAvatarIcon3New} alt="Avatar" /> <p className='botParaClass'> {chat.question}</p></div> : ""}
                                                    {chat.response ? <div className='userMsgClass'> <p className='userParaClass'> {chat.response}</p> <img src="/img/chatSenderIcon.png" alt="Sender Avatar" /></div> : ""}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="current-question " >

                                            {/* <div className='currentBotMsgClass'>    <img src="/img/chatAvatarIcon3.png" alt="Avatar" /> <p className='currentBotParaClass'> {virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex]}</p></div> */}



                                            <div className='currentBotMsgClass'>
                                                <img src={chatAvatarIcon3New} alt="Avatar" />
                                                <p className='currentBotParaClass'>
                                                    {
                                                        virAssistCurrentQuestion &&
                                                            virAssistCurrentQuestion.question &&
                                                            virAssistCurrentQuestion.question.length > virAssistCurrentQuestionIndex
                                                            ? virAssistCurrentQuestion.question[virAssistCurrentQuestionIndex]
                                                            : "Default question text or loading message"
                                                    }
                                                    {
                                                        console.log('virAssistCurrentQuestion.question', virAssistCurrentQuestion.question, virAssistCurrentQuestion.video_path)
                                                    }
                                                </p>
                                            </div>




                                            {virAssistCurrentQuestion.custompayload ? (
                                                <div className="response-options">
                                                    {Object.keys(virAssistCurrentQuestion.custompayload).map((key) => (

                                                        <button key={key} onClick={() => virAssistHandleResponse(key)}>{key}</button>

                                                    ))}
                                                </div>
                                            ) : null}


                                        </div>
                                    </div>

                                    {/* user bot main div ends */}



                                    <div className="hello-container" >

                                        <div>

                                            <video ref={videoRefVirtAssist}
                                                autoPlay
                                                //  onended={handleVideoEnd}

                                                playsInline
                                            >

                                                Your browser does not support the video tag.
                                            </video>

                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className='outputHelpfulClass'>
                                {virAssistErrorMessage && <p className='inputErrorClass'>{virAssistErrorMessage}</p>}
                                <div className="input-container">
                                    <input
                                         type="text"
                                        value={virAssistInputValue}
                                        onChange={(e) => virAssistSetInputValue(e.target.value)}
                                        onKeyDown={virAssistHandleKeyDown}
                                        placeholder="Type your response"
                                    />

                                    {/* <input
                                        type="datetime-local"
                                        value={virAssistInputValue}
                                        onChange={(e) => virAssistSetInputValue(e.target.value)}
                                        onKeyDown={virAssistHandleKeyDown}
                                        placeholder="Select date and time"
                                        min={getCurrentDateTime()} // Set min to the current date and time
                                    /> */}

                                    {isRecordingTemp ? (
                                        <div className="recording-indicator">
                                            <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
                                                <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
                                            <img src="/img/chatMicIcon.png" alt="Mic" />
                                        </div>
                                    )}

                                    <div className='iconButton'
                                        // onClick={virAssistHandleSubmitInput} 
                                        onClick={virAssistInputValue.length > 0 ? virAssistHandleSubmitInput : ''}
                                        disabled={loading || recording}>
                                        <img src="/img/chatSendIcon.png" alt="Send" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='chatBoxImageClass' >
                        {/* <img src={chatBoxImage} alt="" onClick={handleChatBotOpen} /> */}
                        <img src={galaGifCircle} alt="" onClick={handleChatBotOpen} />

                    </div>
            }

        </>

    );
};







export default DirectData;



